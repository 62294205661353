import { useNavigate } from 'react-router';
import {
    useAuth,
    useUserRoles,
} from '../../services/authentication/auth.queries';
import {
    Box,
    Button,
    Flex,
    HStack,
    IconButton,
    SimpleGrid,
    Stack,
    Text,
    chakra,
} from '@chakra-ui/react';
import { Logo } from '../Logo/Logo';
import { AUTH_NAV_ITEMS, PUBLIC_NAV_ITEMS, ROUTES } from '../../App.constant';
import { Link } from 'react-router-dom';
import { FiSearch } from 'react-icons/fi';

import { colors } from '../../styles/colors';
import { IoCloseSharp } from 'react-icons/io5';
import {
    RiDashboardLine,
    RiFacebookLine,
    RiInstagramLine,
    RiLinkedinLine,
    RiPagesLine,
    RiTwitterLine,
} from 'react-icons/ri';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useMemo } from 'react';
import { ROLES_TYPE } from '@primaljournal/commons';

interface Props {
    onClose: () => void;
}
export const PublicSideMenu = ({ onClose }: Props) => {
    const { data } = useAuth();
    const navigate = useNavigate();
    const { roleState } = useUserRoles();

    const dashboardLandingPage: {
        [key in ROLES_TYPE]: {
            to: string;
            label: string;
            icon: ReactJSXElement;
        };
    } = {
        Admin: {
            to: ROUTES.DASHBOARD,
            label: 'Dashboard',
            icon: <RiDashboardLine size={24} />,
        },
        Author: {
            to: ROUTES.MANUSCRIPTS,
            label: 'Manuscripts',
            icon: <RiPagesLine size={24} />,
        },
        Reviewer: {
            to: ROUTES.MANUSCRIPTS,
            label: 'Manuscripts',
            icon: <RiDashboardLine size={24} />,
        },
        Editor: {
            to: ROUTES.MANUSCRIPTS,
            label: 'Manuscripts',
            icon: <RiDashboardLine size={24} />,
        },
        ManagerialEditor: {
            to: ROUTES.MANUSCRIPTS,
            label: 'Manuscripts',
            icon: <RiDashboardLine size={24} />,
        },
        EditorialSecretary: {
            to: ROUTES.MANUSCRIPTS,
            label: 'Manuscripts',
            icon: <RiDashboardLine size={24} />,
        },
        ChiefEditor: {
            to: ROUTES.MANUSCRIPTS,
            label: 'Manuscripts',
            icon: <RiDashboardLine size={24} />,
        },
        Blogger: {
            to: ROUTES.BLOGS,
            label: 'Dashboard',
            icon: <RiDashboardLine size={24} />,
        },
        Support: {
            to: ROUTES.DASHBOARD,
            label: 'Dashboard',
            icon: <RiDashboardLine size={24} />,
        },
    };

    const selectedLanding = useMemo(() => {
        if (
            roleState?.activeRole &&
            dashboardLandingPage[roleState?.activeRole]
        ) {
            return dashboardLandingPage[roleState?.activeRole];
        }

        return null;
    }, [roleState?.activeRole]);

    return (
        <chakra.div position="relative" px={6} py={4} h="100%" overflowY="auto">
            <Flex
                w="full"
                alignItems="left"
                gap={10}
                flexDirection="column"
                justify="space-between"
                h="100%"
            >
                <Box>
                    <Flex
                        gap={4}
                        align="center"
                        w="100%"
                        alignContent="center"
                        justify="space-between"
                    >
                        <Flex to={ROUTES.HOME} as={Link} gap={4} align="center">
                            <Logo h={35} transition="height 0.2s ease-in" />
                            <Text
                                fontWeight="extrabold"
                                fontSize="md"
                                whiteSpace="nowrap"
                            >
                                Primal Journals
                            </Text>
                        </Flex>

                        <Flex gap={2}>
                            <IconButton
                                variant="ghost"
                                color="current"
                                onClick={() => navigate(ROUTES.SEARCH_PAGE)}
                                icon={<FiSearch />}
                                aria-label="Search journals, authors, affiliates"
                                fontSize="20px"
                            />

                            <IconButton
                                onClick={() => onClose()}
                                fontSize="lg"
                                variant="ghost"
                                color="current"
                                icon={<IoCloseSharp />}
                                aria-label="menu-navigation"
                                justifySelf="center"
                                bg={colors.hoverColor}
                            />
                        </Flex>
                    </Flex>

                    <Stack w="100%" ml={{ md: 6 }} gap={4} pt={10}>
                        {PUBLIC_NAV_ITEMS.map((n) => {
                            return (
                                <Link
                                    key={n.href}
                                    to={n.href}
                                    onClick={() => onClose()}
                                >
                                    <Text fontSize="xl" fontWeight="medium">
                                        {n.label}
                                    </Text>
                                </Link>
                            );
                        })}
                        {selectedLanding && (
                            <Link
                                to={selectedLanding.to}
                                onClick={() => onClose()}
                            >
                                <Text
                                    fontSize="xl"
                                    fontWeight="extrabold"
                                    color={colors.primary}
                                >
                                    {selectedLanding.label}
                                </Text>
                            </Link>
                        )}
                    </Stack>
                    {!data && (
                        <SimpleGrid mt={4} gap={4}>
                            {AUTH_NAV_ITEMS.map((navItem, i) => (
                                <Button
                                    key={i}
                                    to={navItem.href}
                                    as={Link}
                                    variant={navItem.variant}
                                    fontSize=".8em"
                                    aria-label={navItem.ariaLabel}
                                >
                                    {navItem.label}
                                </Button>
                            ))}
                        </SimpleGrid>
                    )}
                </Box>
                <Flex gap={4} flexDir="column">
                    <Flex>
                        <Logo />

                        <Text ml="40px" fontSize="sm">
                            Office 4 and 5, Ladeco, <br /> Olusegun Obasanjo
                            Way, Akure, <br />
                            Ondo State, Nigeria.
                        </Text>
                    </Flex>

                    <Flex gap={4} flexDir="column">
                        <HStack ml="70px" alignItems="flex-start">
                            <IconButton
                                variant="outline"
                                colorScheme="primary"
                                aria-label="Primal Instagram Account"
                                icon={<RiInstagramLine />}
                                as={'a'}
                                href={`https://www.instagram.com/primaljournals/`}
                                target="_blank"
                            />
                            <IconButton
                                variant="outline"
                                colorScheme="primary"
                                aria-label="Primal Twitter Account"
                                icon={<RiTwitterLine />}
                                as={'a'}
                                href={`https://twitter.com/primaljournals`}
                                target="_blank"
                            />
                            <IconButton
                                variant="outline"
                                colorScheme="primary"
                                aria-label="Primal Twitter Facebook"
                                icon={<RiFacebookLine />}
                                as={'a'}
                                href={`https://www.facebook.com/Primaljournals`}
                                target="_blank"
                            />
                            <IconButton
                                variant="outline"
                                colorScheme="primary"
                                aria-label="Primal Linkedin"
                                icon={<RiLinkedinLine />}
                                as={'a'}
                                href={`https://www.linkedin.com/company/primal-journals`}
                                target="_blank"
                            />
                        </HStack>
                        <Box ml="70px">
                            <Text>+234 902 453 4541</Text>

                            <Text fontSize="sm">
                                support@primaljournals.org
                            </Text>
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </chakra.div>
    );
};
