import { lazy } from 'react';
import IRoute from './route.interface';
import { ROUTES } from '../App.constant';

const SignIn = lazy(
    () =>
        import('../pages/authentication/SignIn' /* webpackChunkName:"SignIn" */)
);
const SignUp = lazy(
    () =>
        import('../pages/authentication/SignUp' /* webpackChunkName:"SignUp" */)
);
const ForgotPassword = lazy(
    () =>
        import(
            '../pages/authentication/ForgotPassword' /* webpackChunkName:"ForgotPassword" */
        )
);
const ResetPassword = lazy(
    () =>
        import(
            '../pages/authentication/ResetPassword' /* webpackChunkName:"ResetPassword" */
        )
);
const PasswordSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/component/PasswordSettings' /* webpackChunkName:"PasswordSettings" */
        )
);

const AuthenticationRoutes = (): IRoute[] => {
    return [
        {
            element: SignIn,
            path: ROUTES.SIGN_IN,
        },
        {
            element: SignUp,
            path: ROUTES.SIGN_UP,
        },
        {
            element: ForgotPassword,
            path: ROUTES.FORGOT_PASSWORD,
        },
        {
            element: ResetPassword,
            path: ROUTES.RESET_PASSWORD,
        },
        {
            element: PasswordSettings,
            path: ROUTES.PASSWORD_SETTINGS,
        },
    ];
};

export default AuthenticationRoutes;
