import { Box, IconButton, useColorMode } from '@chakra-ui/react';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { useNavExpanded } from '../../lib/utils';
import { colors } from '../../styles/colors';
import Sidebar from '../Navigation/SideBar';
import { SETTINGS_MENUS } from '../../routes/menu.navigation';

export default function SettingsAppWrapper({
    children,
}: {
    children: React.ReactNode;
}) {
    const { colorMode } = useColorMode();
    const { expandedState, setExpanded } = useNavExpanded();

    return (
        <Box
            display="flex"
            flexDirection="row"
            as="section"
            minH="100vh"
            bg={colorMode === 'light' ? colors.gray : ''}
            width="100vw"
            overflowX="hidden"
        >
            <Sidebar
                transition=".3s ease"
                width={expandedState ? '240px' : '65px'}
                display={{ base: 'none', md: 'unset' }}
                menus={SETTINGS_MENUS}
            />

            <IconButton
                display={{ base: 'none', md: 'flex' }}
                ml={expandedState ? '226px' : '52px'}
                bg={colorMode === 'dark' ? 'hoverColor' : 'lightPrimary'}
                position="fixed"
                icon={expandedState ? <FaCaretLeft /> : <FaCaretRight />}
                aria-label="caret"
                rounded="full"
                size="xs"
                zIndex={1111}
                top="50%"
                onClick={() => setExpanded(!expandedState)}
            />

            <Box
                transition=".3s ease"
                width="100%"
                margin={0}
                marginInline={0}
                paddingInlineStart={{
                    base: 0,
                    md: expandedState ? '240px' : '65px',
                }}
            >
                <Box
                    as="main"
                    marginInlineStart={0}
                    height="100vh"
                    overflowY="auto"
                >
                    {children}
                </Box>
            </Box>
        </Box>
    );
}
