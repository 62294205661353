import { colors } from '../colors';
import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode, whiten, darken } from '@chakra-ui/theme-tools';

export const TextareaStyles: ComponentStyleConfig = {
  baseStyle: {
    bg: mode(
      whiten('secondary', 0),
      darken('secondary', 0)
    ) as unknown as string,
    color: 'black',
    _placeholder: {
      color: colors.black,
      opacity: 0.4,
      fontSize: '0.9rem',
    },
  },
  defaultProps: {
    variant: null,
  },
};
