import { ErrorComponentProps } from '../../lib/HOCs/withErrorBoundary';
import { useEffect } from 'react';
import axios from 'axios';
import {
    buildAxiosErrorBlocks,
    buildGeneralErrorBlocks,
} from '../../lib/errUtils';
import { slackSendErrorMessage } from '../../services/slackBugIngest/slackIngestMutations';
import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import { colors } from '../../styles/colors';
import { BiHome } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { MdOutlineBrokenImage } from 'react-icons/md';

const ServerErrorView = ({ error }: ErrorComponentProps) => {
    useEffect(() => {
        if (axios.isAxiosError(error)) {
            const fields = buildAxiosErrorBlocks(error);
            slackSendErrorMessage(fields);
        } else {
            if (error instanceof Error) {
                const fields = buildGeneralErrorBlocks(error);
                slackSendErrorMessage(fields);
            }
        }
    }, [error]);
    console.log({ error });
    return (
        <Stack
            minHeight={'30rem'}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Text fontWeight={800} fontSize={'4rem'}>
                Oops.
            </Text>

            <Flex alignItems={'center'} gap={4}>
                <MdOutlineBrokenImage size={'4rem'} />

                <Text>Something is broken.</Text>
            </Flex>

            <Text>
                Please contact support at{' '}
                <span
                    style={{
                        color: 'black',
                        textDecoration: 'underline',
                    }}
                >
                    <a
                        href={`mailto:support@primaljournals.org`}
                        style={{
                            color: 'black',
                            textDecoration: 'underline',
                        }}
                    >
                        support@primaljournals.org
                    </a>
                </span>
            </Text>
            <Button
                p={4}
                width={'max-content'}
                display={'flex'}
                alignItems={'center'}
                gap={2}
                as={Link}
                to={`/`}
                border={'none'}
                outline={'none'}
                borderRadius={'none'}
                color={colors.primary}
                _hover={{ borderBottom: `2px solid ${colors.primary}` }}
                onClick={() => {
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }}
            >
                Return Home <BiHome />
            </Button>
        </Stack>
    );
};

export default ServerErrorView;
