import { useMutation } from 'react-query';
import { ServerResponse } from '../../hooks/useAxiosConfig';
import { apiHttp } from '../../lib/appConfig';
import { ICreateUser } from './user.interface';
import { IUser } from '@primaljournal/commons';

const createUser = async (user: ICreateUser) => {
    const res = await apiHttp.post<ServerResponse<IUser>>('/auth/users/', user);
    return res.data.data;
};

const deleteUser = async (id: string) => {
    const res = await apiHttp.delete<ServerResponse<IUser>>('/users/' + id);
    return res.data.data;
};

const updateUser = async ({ id, user }: { id: string; user: IUser }) => {
    const res = await apiHttp.patch<ServerResponse<IUser>>(
        '/users/' + id,
        user
    );
    return res.data.data;
};

export const useUpdateUser = () => {
    return useMutation(updateUser);
};

/**
 * Creates a new user
 * @returns Boolean
 */
export const useCreateUser = () => {
    return useMutation(createUser);
};

/**
 * Deletes a new user
 * Treat with caution
 */
export const useDeleteUser = () => {
    return useMutation(deleteUser);
};