import { colors } from './../colors';
import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode, whiten, darken } from '@chakra-ui/theme-tools';

export const InputStyles: ComponentStyleConfig = {
    baseStyle: {
        field: {
            bg: mode(
                whiten('secondary', 0),
                darken('secondary', 0)
            ) as unknown as string,
            color: 'black',
            minHeight: 10,
            _autofill: {
                // bg: 'gray',
            },
            _placeholder: {
                color: colors.black,
                opacity: 0.4,
                fontSize: '0.9rem',
            },
            _dark: {
                color: 'black',
            },
        },
    },
    defaultProps: {
        variant: null, // null here
    },
};
