import { IPublicNavigationItem } from './lib/interfaces/generalTypes';
import JournalMenu from './pages/public/components/JournalMenu';

export const ROUTES = {
    ALL: '*',
    HOME: '/',
    AUTH: '/auth',
    SIGN_UP: '/sign-up',
    SIGN_IN: '/sign-in',
    ARTICLES: '/articles',
    DASHBOARD: '/dashboard',
    MANUSCRIPTS: '/manuscripts',
    LIBRARY: '/library',
    TRANSACTIONS: '/transactions',
    ONBOARDING: '/onboarding',
    JOURNALS: '/journals',
    PEOPLE: '/people',
    SINGLE_JOURNAL: '/single-journals',
    JOURNAL_CATEGORIES: '/journal-categories',
    NEW_JOURNAL_CATEGORIES: '/new-journal-category',
    ABOUT_US: '/about-us',
    CONTACT_US: '/contact-us',
    NEW_MANUSCRIPT: '/new-manuscript',
    USERS: '/users',
    FORGOT_PASSWORD: '/forgot-password',
    RESET_PASSWORD: '/reset-password/:token',
    ETHICS: '/ethics',
    CHIEF_EDITOR: '/chief-editor',
    REVIEWER_DASHBOARD: '/reviewer',
    REVIEW_REQUEST: '/manuscript/review',
    PAYMENT: '/payment',
    PROFILE_PAGE: '/profile',
    INSTITUTIONS: '/institutions',
    PUBLICATIONS: '/publications',
    SETTINGS: '/settings',
    PERSONAL_INFORMATION_SETTINGS: '/settings/personal-information',
    AFFILIATES_SETTINGS: '/settings/affiliates',
    SHIPPING_SETTINGS: '/settings/shipping',
    PRIMAL_SETTINGS: '/primal-settings',
    PASSWORD_SETTINGS: '/settings/password-reset',
    NEW_ISSUE: '/new-issue',
    EDIT_ISSUE: '/edit-issue',
    SEARCH_PAGE: '/search',
    AFFILIATES: '/affiliates',
    AFFILIATE: '/affiliate',
    CART: '/cart',
    PRIVACY_POLICY: '/privacy-policy',
    TERMS_AND_CONDITIONS: '/terms-and-condition',
    FAQS: '/FAQs',
    BECOME_REVIEWER: '/become-reviewer',
    NEWS_AND_BLOGS: '/news-and-blogs',
    NOTIFICATIONS: '/notifications',
    BLOGS: '/blogs',
    NEW_BLOG: '/new-blog',
    UPDATE_BLOG: '/update-blog',
    PODCAST: '/podcast',
    BLOG_TYPE: '/blog-type',
    ISSUES: '/issues',
    REVIEWERS_WAIT_LIST: '/reviewers-wait-list',
    NEW_COLLABORATION: '/new-collaboration',
    EMAIL_VERIFICATION: '/email-verification',
    TEMP_COLLAB: '/temp-collab',
    COLLABORATIONS: '/collaborations',
    NEW_ADVERTISEMENT_PACKAGE: '/new-advertisement-package',
    ADVERTISEMENT_PACKAGES: '/advertisement-packages',
    PUBLIC_COLLABORATIONS: '/public-collaborations',
    PAYMENTS: '/payments',
};

export const TITLE_ENUMS = [
    'Mr.',
    'Mrs.',
    'Miss.',
    'Dr.',
    'Prof.',
    '',
] as const;

export const normalize_property_name = (property: string | undefined) => {
    if (!property) return property;

    const words: { [key: string]: string } = {
        ChiefEditor: 'Chief Editor',
        Admin: 'Administrator',
        ManagerialEditor: 'Managerial Editor',
        EditorialSecretary: 'Editorial Secretary',
        Reviewer: 'Reviewer',
    };

    const normalized = words[property];
    if (!normalized) {
        return property;
    }

    return normalized;
};

export const PUBLIC_NAV_ITEMS: Array<IPublicNavigationItem> = [
    {
        label: 'Home',
        href: ROUTES.HOME,
    },
    {
        label: 'Journals',
        href: `${ROUTES.JOURNALS}?page=1`,
        SubMenuComponent: JournalMenu,
    },
    {
        label: 'Articles',
        href: ROUTES.ARTICLES,
    },
    {
        label: 'Affiliates',
        href: `${ROUTES.AFFILIATES}?page=1`,
    },
    {
        label: 'Collaborations',
        href: ROUTES.PUBLIC_COLLABORATIONS,
    },
    // {
    //     label: 'Dashbord',
    //     href: ROUTES.DASHBOARD,
    //     isButton: true,
    // },

    // {
    //     label: 'People',
    //     href: ROUTES.PEOPLE,
    // },
    // {
    //     label: 'About Us',
    //     href: ROUTES.ABOUT_US,
    // },
    // {
    //     label: 'Contact Us',
    //     href: ROUTES.CONTACT_US,
    // },
];

export const AUTH_NAV_ITEMS: Array<IPublicNavigationItem> = [
    {
        label: 'Sign In',
        href: ROUTES.SIGN_IN,
        variant: 'outline',
        ariaLabel: 'Sign in to Primal Journals',
    },
    {
        label: 'Sign Up',
        href: ROUTES.SIGN_UP,
        variant: 'primary',
        ariaLabel: 'Sign up to Primal Journals',
    },
];
