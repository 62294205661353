import { lazy } from 'react';
import IRoute from './route.interface';
import { ROUTES } from '../App.constant';
import SettingsAppWrapper from '../components/AppWrappers.tsx/SettingsAppWrapper';
import AppWrapper from '../components/AppWrappers.tsx/AppWrapper';

const Manuscripts = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Manuscript/Manuscripts' /* webpackChunkName:"Manuscripts" */
        )
);
const Manuscript = lazy(
    () =>
        import(
            '../pages/authenticatedPages/ChiefEditor/Manuscript' /* webpackChunkName:"Manuscript" */
        )
);
const PersonalSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/pages/PersonalSettings' /* webpackChunkName:"PersonalSettings" */
        )
);
const AffiliatesSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/pages/AffiliatesSettings' /* webpackChunkName:"AffiliatesSettings" */
        )
);
const ShippingSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/pages/ShippingSettings' /* webpackChunkName:"ShippingSettings" */
        )
);
const PasswordSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/component/PasswordSettings' /* webpackChunkName:"PasswordSettings" */
        )
);

const CollaborationPage = lazy(
    () =>
        import(
            '../pages/public/pages/Collaborations/CollaborationPage' /* webpackChunkName:"CollaborationPage */
        )
);

const InvalidRolePage = lazy(
    () =>
        import(
            '../pages/public/pages/InvalidRolePage' /* webpackChunkName:"InvalidRolePage" */
        )
);

const EditorialSecretaryRoute = (): IRoute[] => {
    return [
        {
            element: Manuscripts,
            path: ROUTES.MANUSCRIPTS,
            wrapper: AppWrapper,
        },
        {
            element: Manuscripts,
            path: ROUTES.DASHBOARD,
            wrapper: AppWrapper,
        },
        {
            element: Manuscript,
            path: ROUTES.MANUSCRIPTS + '/:manuscriptId',
            wrapper: AppWrapper,
        },
        {
            element: InvalidRolePage,
            path: ROUTES.NEW_MANUSCRIPT,
            wrapper: AppWrapper,
        },
        {
            element: PersonalSettings,
            path: ROUTES.SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: PersonalSettings,
            path: ROUTES.PERSONAL_INFORMATION_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: AffiliatesSettings,
            path: ROUTES.AFFILIATES_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: ShippingSettings,
            path: ROUTES.SHIPPING_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: PasswordSettings,
            path: ROUTES.PASSWORD_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: PasswordSettings,
            path: ROUTES.PASSWORD_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: CollaborationPage,
            path: ROUTES.PUBLIC_COLLABORATIONS + '/:id',
        },
    ];
};

export default EditorialSecretaryRoute;
