import { Center, Flex, Text, useColorMode } from '@chakra-ui/react';
import lightSpinner from '!file-loader!./lightSpinner.svg';
import darkSpinner from '!file-loader!./darkSpinner.svg';
import create from 'zustand';
import { useBaseColor } from '../../styles/theme';

type IProps = {
    message: string;
    loading: boolean;
    setLoading: (state: boolean, message?: string) => void;
};

export const useLoadingSpinner = create<IProps>((set) => ({
    message: '',
    loading: false,
    setLoading: (loadingState: boolean, message?: string) =>
        set(() => ({ loading: loadingState, message })),
}));

const PageSkeleton = ({
    height = '100%',
    message = '',
}: {
    loadingText?: string;
    height?: string;
    message?: string;
}) => {
    const { colorMode } = useColorMode();
    const { bg } = useBaseColor(colorMode, 0.8);

    return (
        <Flex
            height={height}
            position="absolute"
            zIndex={999}
            justifyContent={'center'}
            justify={'center'}
            w="100%"
            background={bg}
        >
            <Center h="100%">
                <Flex
                    h="100%"
                    flexDirection={'column'}
                    alignItems={'center'}
                    justifyContent={'center'}
                    justify={'center'}
                >
                    <img
                        src={colorMode === 'dark' ? darkSpinner : lightSpinner}
                        alt="Loading..."
                        style={{ height: 200, width: 200 }}
                    />

                    {Boolean(message) && <Text>{message}</Text>}
                </Flex>
            </Center>
        </Flex>
    );
};

export default PageSkeleton;
