import {
    useDisclosure,
    chakra,
    Flex,
    Text,
    IconButton,
    Drawer,
    DrawerOverlay,
    DrawerContent,
} from '@chakra-ui/react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../App.constant';
import { Logo } from '../Logo/Logo';
import { RiMenu3Line } from 'react-icons/ri';
import { colors } from '../../styles/colors';
import { FiSearch } from 'react-icons/fi';
import { PublicSideMenu } from './PublicSideMenu';

export const MobilePublicHeader = () => {
    const navigate = useNavigate();
    const sidebar = useDisclosure();

    return (
        <chakra.div position="relative" px={4} py={4}>
            <Drawer
                isOpen={sidebar.isOpen}
                onClose={sidebar.onClose}
                placement="right"
                autoFocus={false}
            >
                <DrawerOverlay />
                <DrawerContent maxW="100%">
                    <PublicSideMenu onClose={sidebar.onClose} />
                </DrawerContent>
            </Drawer>

            <Flex
                w="full"
                alignItems="center"
                justifyContent="space-between"
                gap={4}
            >
                <Flex
                    gap={4}
                    align="center"
                    w="100%"
                    alignContent="center"
                    justify="space-between"
                >
                    <Flex to={ROUTES.HOME} as={Link} gap={4} align="center">
                        <Logo h={35} transition="height 0.2s ease-in" />
                        <Text
                            fontWeight="extrabold"
                            fontSize="md"
                            whiteSpace="nowrap"
                        >
                            Primal Journals
                        </Text>
                    </Flex>

                    <Flex gap={2}>
                        <IconButton
                            variant="ghost"
                            color="current"
                            onClick={() => navigate(ROUTES.SEARCH_PAGE)}
                            icon={<FiSearch />}
                            aria-label="Search journals, authors, affiliates"
                            fontSize="20px"
                        />

                        <IconButton
                            onClick={() => sidebar.onToggle()}
                            fontSize="lg"
                            variant="ghost"
                            color="current"
                            icon={<RiMenu3Line />}
                            aria-label="menu-navigation"
                            justifySelf="center"
                            bg={colors.hoverColor}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </chakra.div>
    );
};
