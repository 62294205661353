import { Flex, SimpleGrid, Stack, Text } from '@chakra-ui/react';
import {
    IPublicNavigationItem,
    IQuerySearchParam,
} from '../../lib/interfaces/generalTypes';
import { BsJournalMedical } from 'react-icons/bs';
import { MdOutlineSchool } from 'react-icons/md';
import { RiArticleLine, RiUserSearchLine } from 'react-icons/ri';
import { BiChevronRight } from 'react-icons/bi';
import { colors } from '../../styles/colors';
import { Link, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../App.constant';

const links: IPublicNavigationItem[] = [
    {
        label: 'Articles',
        icon: <RiArticleLine />,
        tab: 'Articles',
        href: ROUTES.ARTICLES,
    },
    {
        label: 'Journals',
        icon: <BsJournalMedical />,
        tab: 'Journals',
        href: ROUTES.JOURNALS,
    },
    {
        label: 'People',
        icon: <RiUserSearchLine />,
        tab: 'People',
        href: ROUTES.PEOPLE,
    },
    {
        label: 'Affiliates',
        icon: <MdOutlineSchool />,
        tab: 'Affiliates',
        href: ROUTES.AFFILIATES,
    },
];

interface IProps {
    keyword: string;
}
const QuickSearchLinks = ({ keyword }: IProps) => {
    const [searchParams] = useSearchParams();

    const accessLink = (link: IPublicNavigationItem) => {
        const paramsString = Object.fromEntries(searchParams.entries());
        const updatedParams: IQuerySearchParam = {
            ...paramsString,
            tab: link.tab as string,
            search_value: keyword,
            page: '1',
        };

        const queryParams = new URLSearchParams(updatedParams);
        const queryString = queryParams.toString();

        return `${ROUTES.SEARCH_PAGE}?${queryString}`;
    };

    return (
        <Stack minW={{ base: '100%' }} maxW={{ base: '100%' }}>
            {Boolean(keyword) && (
                <Text fontSize="sm" maxW={'100%'} fontWeight="medium">
                    Search for <strong>"{keyword}"</strong> in
                </Text>
            )}

            {!Boolean(keyword) && (
                <Text fontSize="sm" fontWeight="medium">
                    Quick Links
                </Text>
            )}

            <SimpleGrid columns={{ base: 1, md: 2 }} gap={4}>
                {links.map((n, i) => (
                    <Flex
                        as={Link}
                        to={accessLink(n)}
                        p={2}
                        key={i}
                        border="1px solid"
                        borderColor={colors.borderColor}
                        justify="space-between"
                        align="center"
                        borderRadius={6}
                        _hover={{ bg: colors.hoverColor }}
                        cursor="pointer"
                    >
                        <Flex align="center" gap={4}>
                            {n.icon}
                            <Text>{n.label}</Text>
                        </Flex>
                        <BiChevronRight />
                    </Flex>
                ))}
            </SimpleGrid>
        </Stack>
    );
};

export default QuickSearchLinks;
