import React, { SetStateAction } from 'react';

import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Stack,
    Text,
} from '@chakra-ui/react';
import { colors } from '../../styles/colors';
import { useNavigate } from 'react-router';
import { ROUTES } from '../../App.constant';

const AuthModal = ({
    showAuthModal,
    setShowAuthModal,
    isCreatingManuscript,
}: {
    showAuthModal: boolean;
    setShowAuthModal: React.Dispatch<SetStateAction<boolean>>;
    isCreatingManuscript?: boolean;
}) => {
    const navigate = useNavigate();

    return (
        <>
            <Modal
                isOpen={showAuthModal}
                onClose={() => setShowAuthModal(false)}
                isCentered
            >
                <ModalOverlay />
                <ModalContent cursor={'pointer'}>
                    <ModalBody
                        py={'2rem'}
                        minH={'10rem'}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={'1rem'}
                        minW={'max-content'}
                        rounded={'lg'}
                        background={'white'}
                    >
                        <Stack
                            gap={2}
                            alignItems={'center'}
                            width={'70%'}
                            mx={'auto'}
                        >
                            <ModalCloseButton
                                onClick={() => {
                                    setShowAuthModal(false);
                                }}
                            />
                            <Text fontWeight={'bold'} fontSize={'lg'}>
                                {' '}
                                You're signed out
                            </Text>

                            <Flex
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                width={'100%'}
                            >
                                <Button
                                    fontSize="md"
                                    background={colors.secondary}
                                    width={'max-content'}
                                    onClick={() => {
                                        // if (!data) {
                                        //     localStorage.setItem(
                                        //         'willRedirect',
                                        //         `${collaboration._id}`
                                        //     );
                                        // }
                                        if (isCreatingManuscript) {
                                            localStorage.setItem(
                                                'createManuscriptFromHomePage',
                                                `yes`
                                            );
                                        }

                                        navigate(ROUTES.SIGN_IN);
                                    }}
                                >
                                    Sign In
                                </Button>
                                <Text>Or</Text>
                                <Button
                                    fontSize="md"
                                    variant="primary"
                                    width={'max-content'}
                                    px={4}
                                    py={2}
                                    onClick={() => {
                                        navigate(ROUTES.SIGN_UP);
                                    }}
                                >
                                    Sign up
                                </Button>
                            </Flex>
                            <Text alignSelf={'start'}>To continue</Text>
                        </Stack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AuthModal;
