import { lazy } from 'react';
import IRoute from './route.interface';
import { ROUTES } from '../App.constant';
import { IAuth } from '@primaljournal/commons';
import SettingsAppWrapper from '../components/AppWrappers.tsx/SettingsAppWrapper';
import AppWrapper from '../components/AppWrappers.tsx/AppWrapper';

const Transactions = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Transactions' /* webpackChunkName:"Transactions" */
        )
);
const Onboarding = lazy(
    () =>
        import(
            '../pages/onboarding/Onboarding' /* webpackChunkName:"Onboarding" */
        )
);
const CreateManuscriptWizard = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Author/CreateManuscriptWizard' /* webpackChunkName:"CreateManuscriptWizard" */
        )
);
const Manuscripts = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Manuscript/Manuscripts' /* webpackChunkName:"Manuscripts" */
        )
);
const Payment = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Manuscript/Payment' /* webpackChunkName:"Payment" */
        )
);
const PersonalSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/pages/PersonalSettings' /* webpackChunkName:"PersonalSettings" */
        )
);
const AffiliatesSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/pages/AffiliatesSettings' /* webpackChunkName:"AffiliatesSettings" */
        )
);
const Manuscript = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Author/Manuscript' /* webpackChunkName:"Manuscript" */
        )
);
const ShippingSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/pages/ShippingSettings' /* webpackChunkName:"ShippingSettings" */
        )
);
const PasswordSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/component/PasswordSettings' /* webpackChunkName:"PasswordSettings" */
        )
);

const Collaborations = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Author/Collaborations' /* webpackChunkName:"CreateManuscriptWizard" */
        )
);

const CreateCollaboration = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Author/CreateCollaboration' /* webpackChunkName:"CreateManuscriptWizard" */
        )
);

const EditCollaboration = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Author/EditCollaboration' /* webpackChunkName:"CreateManuscriptWizard" */
        )
);
const Collaboration = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Author/Collaboration' /* webpackChunkName:"Collaboration" */
        )
);

const UpgradeCollaboration = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Author/UpgradeCollaboration' /* webpackChunkName:"UpgradeCollaboration" */
        )
);

const AuthorRoute = (auth: IAuth): IRoute[] => {
    return [
        !auth.user?.basicInformationCompleted
            ? {
                  element: Onboarding,
                  path: ROUTES.DASHBOARD,
              }
            : {
                  element: Manuscripts,
                  path: ROUTES.MANUSCRIPTS,
                  wrapper: AppWrapper,
              },

        {
            element: Manuscripts,
            path: ROUTES.DASHBOARD,
            wrapper: AppWrapper,
        },
        {
            element: Manuscript,
            path: ROUTES.MANUSCRIPTS + '/:manuscriptId',
            wrapper: AppWrapper,
        },
        {
            element: Transactions,
            path: ROUTES.TRANSACTIONS,
            wrapper: AppWrapper,
        },
        {
            element: Collaborations,
            path: ROUTES.COLLABORATIONS,
            wrapper: AppWrapper,
        },
        {
            element: CreateCollaboration,
            path: ROUTES.NEW_COLLABORATION,
            wrapper: AppWrapper,
        },

        {
            element: CreateCollaboration,
            path: ROUTES.NEW_COLLABORATION + '/:collaborationId',
            wrapper: AppWrapper,
        },

        {
            element: Collaboration,
            path: ROUTES.COLLABORATIONS + '/:collaborationId',
            wrapper: AppWrapper,
        },
        {
            element: EditCollaboration,
            path: ROUTES.COLLABORATIONS + '/:collaborationId' + '/edit',
            wrapper: AppWrapper,
        },
        {
            element: UpgradeCollaboration,
            path:
                ROUTES.COLLABORATIONS +
                '/:collaborationId' +
                '/upgrade-ad-package',
            wrapper: AppWrapper,
        },
        {
            element: CreateManuscriptWizard,
            path: ROUTES.NEW_MANUSCRIPT,
            wrapper: AppWrapper,
        },
        {
            element: CreateManuscriptWizard,
            path: ROUTES.NEW_MANUSCRIPT + '/:manuscriptId',
            wrapper: AppWrapper,
        },
        {
            element: Payment,
            path: ROUTES.MANUSCRIPTS + '/:manuscriptId' + ROUTES.PAYMENT,
            wrapper: AppWrapper,
        },
        {
            element: PersonalSettings,
            path: ROUTES.SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: PersonalSettings,
            path: ROUTES.PERSONAL_INFORMATION_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: AffiliatesSettings,
            path: ROUTES.AFFILIATES_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: ShippingSettings,
            path: ROUTES.SHIPPING_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: PasswordSettings,
            path: ROUTES.PASSWORD_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
    ];
};

export default AuthorRoute;
