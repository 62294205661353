import { INewsLetterRecipient } from './newsLetterRecipients.model';
import { useMutation } from 'react-query';
import { ServerResponse } from '../../hooks/useAxiosConfig';
import { apiHttp } from '../../lib/appConfig';

const createNewsLetterRecipient = async (recipient: INewsLetterRecipient) => {
    const update = await apiHttp.post<ServerResponse<INewsLetterRecipient>>(
        '/newsletter/recipients',
        recipient
    );

    return update.data.data;
};

export const useCreateNewsLetterRecipient = () => {
    return useMutation(createNewsLetterRecipient);
};
