import { Box, chakra, useBoolean, useEventListener } from '@chakra-ui/react';
import React from 'react';
import { colors } from '../../styles/colors';
import { DesktopPublicHeader } from './DesktopPublicHeader';
import { MobilePublicHeader } from './MobilePublicHeader';

export const PublicHeader = () => {
    const [isScrolling, setFlag] = useBoolean(window.pageYOffset > 200);

    useEventListener('scroll', () => {
        const isScrolling = window.pageYOffset > 200;
        if (isScrolling) {
            setFlag.on();
        } else {
            setFlag.off();
        }
    });

    return (
        <chakra.header
            w="full"
            overflowY="hidden"
            position="fixed"
            zIndex={999}
            top={0}
            bg={isScrolling ? colors.white : colors.white}
            transition="background-color 0.3s ease-in"
            borderBottom={isScrolling ? '1px' : 0}
            borderColor={colors.borderColor}
        >
            <Box display={{ base: 'none', lg: 'block' }}>
                <DesktopPublicHeader />
            </Box>
            <Box display={{ lg: 'none' }}>
                <MobilePublicHeader />
            </Box>
        </chakra.header>
    );
};
