import {
    InputGroup,
    InputLeftElement,
    Input,
    Box,
    LayoutProps,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    useEventListener,
    PlacementWithLogical,
} from '@chakra-ui/react';
import { IoSearch } from 'react-icons/io5';
import { useRef, useState } from 'react';
import { colors } from '../../styles/colors';
import QuickSearchLinks from './QuickSearchLinks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../App.constant';

interface IProps {
    bg: string;
    width?: LayoutProps['width'];
    display?: LayoutProps['display'];
    matchWidth?: boolean;
    placement: PlacementWithLogical;
}

const PublicSearchInput = ({ bg, display, placement }: IProps) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const searchValue = searchParams.get('search_value');
    const routeSearchValue = searchParams.get('search');
    const [search, setSearch] = useState<string>(
        searchValue || routeSearchValue || ''
    );
    const [open, setOpen] = useState(false);

    const searchRef = useRef<HTMLInputElement>(null);
    const popoverRef = useRef<HTMLDivElement>(null);

    const onFocus = () => setOpen(true);

    useEventListener('scroll', () => {
        setOpen(false);
    });

    const handleSubmit = (event?: React.FormEvent<HTMLFormElement>): void => {
        event?.preventDefault();
        const paramsString = Object.fromEntries(searchParams.entries());
        const updatedParams = { ...paramsString, search_value: search };
        delete (updatedParams as any).page;
        const queryParams = new URLSearchParams(updatedParams);
        const queryString = queryParams.toString();
        setOpen(false);

        navigate(`${ROUTES.SEARCH_PAGE}?${queryString}`);
    };

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
        if (event.key === 'Enter') {
            handleSubmit();
            setOpen(false);
            event?.preventDefault();
        }
    };

    useEventListener('click', (event: MouseEvent) => {
        if (
            popoverRef.current &&
            !popoverRef.current.contains(event.target as HTMLElement)
        ) {
            setOpen(false);
        }
    });

    return (
        <Box
            // w={{ base: '95%', lg: '100%' }}
            display={display || 'block'}
            ref={popoverRef}
            width={'100%'}
        >
            <Popover
                isOpen={open}
                placement={placement}
                initialFocusRef={searchRef}
                gutter={0}
                returnFocusOnClose={true}
            >
                <PopoverTrigger>
                    <Box
                        as="form"
                        onSubmit={handleSubmit}
                        onKeyDown={(e: any) => onKeyDown(e)}
                    >
                        <InputGroup>
                            <InputLeftElement
                                children={<IoSearch />}
                                textAlign="center"
                                fontSize="20px"
                                fontWeight="bolder"
                                height="43px"
                            />
                            <Input
                                border="1px solid"
                                value={search || ''}
                                onChange={(e) => setSearch(e.target.value)}
                                onClick={onFocus}
                                onFocus={onFocus}
                                id="search-input"
                                ref={searchRef}
                                placeholder="Search article, people, affiliate..."
                                height="43px"
                                bg={bg}
                                // width={width}

                                aria-labelledby="search-input"
                                autoComplete="off"
                                autoCapitalize="off"
                                type="search"
                                borderBottom={open ? 'none' : '1px solid'}
                                borderColor={colors.borderColor}
                                borderBottomRightRadius={open ? 0 : 6}
                                borderBottomLeftRadius={open ? 0 : 6}
                            />
                        </InputGroup>
                    </Box>
                </PopoverTrigger>

                <Portal>
                    <PopoverContent
                        // border="none"
                        borderTop="1px solid"
                        borderColor={colors.borderColor}
                        width={{ base: '90vw', md: '96vw', lg: '50rem' }}
                        rounded="0"
                        roundedBottomRight="xl"
                        roundedBottomLeft="xl"
                        margin={{ base: 'auto', lg: 'auto' }}
                        bg={bg}
                    >
                        <PopoverBody>
                            <Box>
                                <QuickSearchLinks keyword={search} />
                            </Box>
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover>
        </Box>
    );
};

export default PublicSearchInput;
