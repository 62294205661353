/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation } from 'react-query';
import { ServerResponse } from '../../hooks/useAxiosConfig';
import { apiHttp } from '../../lib/appConfig';
import { isDev } from '../../lib/utils';
import { ISlackField, ISlackBugPayload } from './slackIngestTypes';

export async function slackSendErrorMessage(fields: ISlackField[]) {
    // upload screenshot
    if (isDev()) return;
}

export async function slackSendBug(newBug: ISlackBugPayload) {
    const res = await apiHttp.post<ServerResponse<ISlackBugPayload>>(
        'v1/auth/debugger',
        newBug
    );
    return res.data.data;
}

// Mutations
export const useSendBug = () => {
    return useMutation(slackSendBug, {
        onSuccess: () => {
            console.log('Error captured');
        },
    });
};
