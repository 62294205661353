import { Flex, Icon, Stack, Text, useColorMode } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { IconType } from 'react-icons';
import { useMatch, useResolvedPath } from 'react-router';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../App.constant';
import { useNavExpanded } from '../../lib/utils';
import { useDynamicLightOrDarkColor } from '../../styles/theme';
import { IMenuItem } from '../../routes/menu.navigation';
import useIsMobile from '../../hooks/useIsMobile';

interface MenuProps {
    icon: IconType | ReactNode | any;
    children: React.ReactNode;
    to: keyof typeof ROUTES | string;
    handler?: VoidFunction;
}

interface IProps extends MenuProps {
    subMenus?: IMenuItem[];
}

const NavItem = ({ icon, children, to, subMenus, handler }: IProps) => {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath?.pathname, end: true });
    const { expandedState } = useNavExpanded();
    const { colorMode } = useColorMode();
    const { black, ghostGray } = useDynamicLightOrDarkColor(colorMode);
    const isMobile = useIsMobile();

    return (
        <Stack
            w="100%"
            onClick={(e) => {
                if (handler) {
                    e.preventDefault();
                    e.stopPropagation();
                    handler();
                }
            }}
        >
            <Flex
                rounded="lg"
                py={2}
                px={4}
                to={to}
                as={Link}
                display="flex"
                justifyItems="left"
                w="100%"
                bg={isActive ? 'hoverColor' : 'ghost'}
                fontWeight={isActive ? 'bold' : ''}
                color={isActive ? black : ghostGray}
                _hover={{
                    bg: 'hoverColor',
                }}
                align="center"
                justify={expandedState ? '' : 'center'}
                gap={2}
                transition=".3s ease"
                style={{ width: '100%' }}
            >
                {icon && (
                    <Icon
                        boxSize={expandedState && !isMobile ? '5' : '4'}
                        as={icon}
                    />
                )}
                {expandedState && (
                    <Text
                        display={{
                            base: !expandedState && !isMobile ? 'none' : '',
                        }}
                    >
                        {children}
                    </Text>
                )}
            </Flex>
            {subMenus && subMenus?.length > 0 && (
                <Stack pl={expandedState ? 4 : 0} w="100%">
                    {subMenus.map((menu, index) => (
                        <NavItem
                            key={index}
                            icon={menu.icon}
                            to={menu.route}
                            handler={menu.handler}
                        >
                            {menu.label}
                        </NavItem>
                    ))}
                </Stack>
            )}
        </Stack>
    );
};

export default NavItem;
