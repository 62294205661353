import { lazy } from 'react';
import IRoute from './route.interface';
import { ROUTES } from '../App.constant';
import SettingsAppWrapper from '../components/AppWrappers.tsx/SettingsAppWrapper';
import AppWrapper from '../components/AppWrappers.tsx/AppWrapper';

const Users = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/Users' /* webpackChunkName:"Users" */
        )
);
const JournalsCategories = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Journals/JournalsCategories' /* webpackChunkName:"JournalsCategories" */
        )
);
const AddEditJournalsCategory = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Journals/AddEditJournalsCategory' /* webpackChunkName:"AddEditJournalsCategory" */
        )
);
const Ethics = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/Ethics' /* webpackChunkName:"Ethics" */
        )
);
const Blogs = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/Blog/Blogs' /* webpackChunkName:"Blogs" */
        )
);
const CreateBlog = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/Blog/CreateBlog' /* webpackChunkName:"CreateBlog" */
        )
);
const Blog = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/Blog/Blog' /* webpackChunkName:"Blog" */
        )
);
const UpdateBlog = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/Blog/components/UpdateBlog' /* webpackChunkName:"UpdateBlog" */
        )
);
const Issues = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Issue/Issues' /* webpackChunkName:"Issues" */
        )
);
const Issue = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Issue/Issue' /* webpackChunkName:"Issue" */
        )
);
const CreateOrEditIssue = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Issue/CreateOrEditIssue' /* webpackChunkName:"CreateOrEditIssue" */
        )
);
const PodVid = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/Blog/PodVid' /* webpackChunkName:"PodVid" */
        )
);
const PersonalSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/pages/PersonalSettings' /* webpackChunkName:"PersonalSettings" */
        )
);
const AffiliatesSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/pages/AffiliatesSettings' /* webpackChunkName:"AffiliatesSettings" */
        )
);
const ShippingSettings = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Shared/Settings/pages/ShippingSettings' /* webpackChunkName:"ShippingSettings" */
        )
);
const Reviewers = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/Reviewers/Reviewers' /* webpackChunkName:"Reviewers" */
        )
);

const Manuscripts = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/Manuscript/Manuscripts' /* webpackChunkName:"Manuscripts" */
        )
);

const Manuscript = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/Manuscript/Manuscript' /* webpackChunkName:"Manuscript" */
        )
);

const CreateAdvertisementPackage = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/AdvertisementPackage/CreateAdvertisementPackage' /* webpackChunkName:"CreateAdvertisementPackage" */
        )
);
const AdvertisementPackages = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/AdvertisementPackage/AdvertisementPackages' /* webpackChunkName:"AdvertisementPackages" */
        )
);

const Collaborations = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/Collaborations/Collaborations' /* webpackChunkName:"Collaborations" */
        )
);

const Collaboration = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/Collaborations/Collaboration' /* webpackChunkName:"Collaboration" */
        )
);

const PrimalAssets = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/PrimalAssets/PrimalAssets' /* webpackChunkName:"PrimalAssets" */
        )
);

const InvalidRolePage = lazy(
    () =>
        import(
            '../pages/public/pages/InvalidRolePage' /* webpackChunkName:"InvalidRolePage" */
        )
);

const Payments = lazy(
    () =>
        import(
            '../pages/authenticatedPages/Admin/Payments/Payments' /* webpackChunkName:"Payments" */
        )
);

const AdminRoute = (): IRoute[] => {
    return [
        {
            element: Users,
            path: ROUTES.DASHBOARD,
            wrapper: AppWrapper,
        },
        {
            element: Users,
            path: ROUTES.USERS,
            wrapper: AppWrapper,
        },
        {
            element: Manuscripts,
            path: ROUTES.MANUSCRIPTS,
            wrapper: AppWrapper,
        },
        {
            element: Manuscript,
            path: ROUTES.MANUSCRIPTS + '/:manuscriptId',
            wrapper: AppWrapper,
        },
        {
            element: InvalidRolePage,
            path: ROUTES.NEW_MANUSCRIPT,
            wrapper: AppWrapper,
        },
        {
            element: JournalsCategories,
            path: ROUTES.JOURNAL_CATEGORIES,
            wrapper: AppWrapper,
        },
        {
            element: AddEditJournalsCategory,
            path: ROUTES.NEW_JOURNAL_CATEGORIES,
            wrapper: AppWrapper,
        },
        {
            element: AddEditJournalsCategory,
            path: ROUTES.JOURNAL_CATEGORIES + '/:journalCategoryId',
            wrapper: AppWrapper,
        },
        {
            element: Ethics,
            path: ROUTES.ETHICS,
            wrapper: AppWrapper,
        },
        {
            element: Blogs,
            path: ROUTES.BLOGS,
            wrapper: AppWrapper,
        },
        {
            element: CreateBlog,
            path: ROUTES.NEW_BLOG,
            wrapper: AppWrapper,
        },
        {
            element: Reviewers,
            path: ROUTES.REVIEWERS_WAIT_LIST,
            wrapper: AppWrapper,
        },
        {
            element: Collaborations,
            path: ROUTES.COLLABORATIONS,
            wrapper: AppWrapper,
        },
        {
            element: Collaboration,
            path: ROUTES.COLLABORATIONS + '/:collaborationId',
            wrapper: AppWrapper,
        },
        {
            element: Issues,
            path: ROUTES.PUBLICATIONS,
            wrapper: AppWrapper,
        },
        {
            element: CreateOrEditIssue,
            path: ROUTES.NEW_ISSUE,
            wrapper: AppWrapper,
        },
        {
            element: CreateOrEditIssue,
            path: ROUTES.EDIT_ISSUE + '/:publicationId',
            wrapper: AppWrapper,
        },
        {
            element: Issue,
            path: ROUTES.PUBLICATIONS + '/:publicationId',
            wrapper: AppWrapper,
        },
        {
            element: Payments,
            path: ROUTES.PAYMENTS,
            wrapper: AppWrapper,
        },
        {
            element: CreateAdvertisementPackage,
            path: ROUTES.NEW_ADVERTISEMENT_PACKAGE,
            wrapper: AppWrapper,
        },
        {
            element: AdvertisementPackages,
            path: ROUTES.ADVERTISEMENT_PACKAGES,
            wrapper: AppWrapper,
        },
        {
            element: Blog,
            path: ROUTES.NEW_BLOG + '/:blogID',
            wrapper: AppWrapper,
        },
        {
            element: UpdateBlog,
            path: ROUTES.NEW_BLOG + '/:blogID' + ROUTES.UPDATE_BLOG,
            wrapper: AppWrapper,
        },
        {
            element: PodVid,
            path: ROUTES.BLOG_TYPE + '/:blogID',
            wrapper: AppWrapper,
        },
        {
            element: PersonalSettings,
            path: ROUTES.SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: PersonalSettings,
            path: ROUTES.PERSONAL_INFORMATION_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: AffiliatesSettings,
            path: ROUTES.AFFILIATES_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: ShippingSettings,
            path: ROUTES.SHIPPING_SETTINGS,
            wrapper: SettingsAppWrapper,
        },
        {
            element: PrimalAssets,
            path: ROUTES.PRIMAL_SETTINGS,
            wrapper: AppWrapper,
        },
    ];
};

export default AdminRoute;
