import {
    Button,
    Menu,
    Popover,
    PopoverTrigger,
    PopoverContent,
    Portal,
    PopoverBody,
} from '@chakra-ui/react';
import { IPublicNavigationItem } from '../../lib/interfaces/generalTypes';
import { colors } from '../../styles/colors';
import { Link } from 'react-router-dom';
import { shadows } from '../../styles/shadow';

const PrimalMenu = ({
    label,
    SubMenuComponent,
    href,
    ariaLabel,
    variant,
    padding,
}: IPublicNavigationItem) => {
    return (
        <Menu>
            <Popover trigger="hover">
                {({ isOpen, onClose }) => (
                    <>
                        <PopoverTrigger>
                            <Button
                                aria-label={ariaLabel}
                                rounded={4}
                                minW={75}
                                to={href}
                                as={Link}
                                variant={variant}
                                fontSize="sm"
                                paddingX={padding ? padding : ''}
                                style={{
                                    textDecoration: 'none',
                                }}
                                _hover={{
                                    bg: colors.hoverColor,
                                }}
                                fontWeight="bold"
                            >
                                {label}
                            </Button>
                        </PopoverTrigger>
                        {SubMenuComponent && isOpen && (
                            <Portal>
                                <PopoverContent
                                    boxShadow={shadows.default}
                                    rounded="xl"
                                    bg="white"
                                    border="none"
                                    maxW={400}
                                    w="100%"
                                >
                                    <PopoverBody>
                                        <SubMenuComponent onClose={onClose} />
                                    </PopoverBody>
                                </PopoverContent>
                            </Portal>
                        )}
                    </>
                )}
            </Popover>
        </Menu>
    );
};

export default PrimalMenu;
