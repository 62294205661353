import React from 'react';
import { Box, ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import PageSkeleton, {
    useLoadingSpinner,
} from './components/skeletons/PageSkeleton';
import { withSuspense } from './lib/HOCs/withSuspense';
import * as Sentry from '@sentry/react';
import { withErrorBoundary } from './lib/HOCs/withErrorBoundary';
import useAxiosConfig from './hooks/useAxiosConfig';
import { theme } from './styles/theme';
import RouteController from './routes/controller.route';
import { Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';

const App = () => {
    useAxiosConfig();
    const { loading, message } = useLoadingSpinner();
    return (
        <HelmetProvider>
            <ChakraProvider theme={theme}>
                <ColorModeScript initialColorMode="light" />
                <Box maxW="100%">
                    {loading && (<PageSkeleton height="100vh" message={message} />)}
                    <Suspense fallback={<PageSkeleton height="100vh" />}>
                        <RouteController />
                    </Suspense>
                </Box>
            </ChakraProvider>
        </HelmetProvider>
    );
};

export default withErrorBoundary(
    withSuspense(Sentry.withProfiler(App), {
        fallBackComponent: <PageSkeleton height="100vh" />,
    }),
    { errorComponentProps: { className: 'h-screen' } }
);
