import {
    Box,
    Flex,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Portal,
    SimpleGrid,
    Text,
    useColorMode,
    Avatar,
    BoxProps,
    // Stack,
    useDisclosure,
    Toast,
    Button,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { normalize_property_name, ROUTES } from '../../App.constant';
import {
    useAuth,
    useUserRoles,
} from '../../services/authentication/auth.queries';
import { useBaseColor, useDynamicLightOrDarkColor } from '../../styles/theme';
import { Logo } from '../Logo/Logo';
import NavItem from './NavItems';
import { SideNavProfilePopup } from './SideNavProfilePopup';
import { useNavExpanded } from '../../lib/utils';
import useModeColor from '../../hooks/useDynamicModeColor';
import { IMenu } from '../../routes/menu.navigation';
import { shadows } from '../../styles/shadow';
import { colors } from '../../styles/colors';
import { FiSettings } from 'react-icons/fi';
import useIsMobile from '../../hooks/useIsMobile';
import ActionModal from '../manuscript/ActionModal';
import { useUpdateUser } from '../../services/user/user.mutation';
import { IUser } from '@primaljournal/commons';

import { IoAirplaneOutline } from 'react-icons/io5';

interface Props extends BoxProps {
    menus: IMenu;
}
const Sidebar = ({ menus, width, ...rest }: Props) => {
    const { data, refetch } = useAuth();
    const initialFocusRef = useRef<any>(null);
    const [isOpen, setIsOpen] = useState(false);
    const open = () => setIsOpen(!isOpen);
    const close = () => setIsOpen(false);
    const { colorMode } = useColorMode();
    const { bg } = useBaseColor(colorMode, 1);
    const { expandedState: isExpanded } = useNavExpanded();
    const { borderColor } = useModeColor();
    const { roleState } = useUserRoles();
    const isMobile = useIsMobile();
    const { black, ghostGray } = useDynamicLightOrDarkColor(colorMode);
    const location = useLocation();
    const { pathname } = location;
    const [showVacationModal, setShowVacationModal] = useState<boolean>(false);
    const [isActivating, setIsActivating] = useState<boolean>(false);
    const { mutateAsync: updateUser } = useUpdateUser();
    const { onClose } = useDisclosure();

    const onToggleVacationMode = async () => {
        try {
            setIsActivating(true);
            if (data) {
                await updateUser({
                    id: data.user?._id,
                    user: {
                        isVacationMode: data?.user?.isVacationMode
                            ? false
                            : true,
                    } as IUser,
                });
                setIsActivating(false);
            }
            setShowVacationModal(false);
            Toast({
                title: `Successfully ${
                    data?.user?.isVacationMode ? `deactivated` : `activated`
                } Vacation Mode`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            await refetch();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <ActionModal
                Function={onToggleVacationMode}
                buttonText={
                    data?.user?.isVacationMode ? 'Deactivate' : 'Activate'
                }
                isOpen={showVacationModal}
                setIsOpen={setShowVacationModal}
                onClose={onClose}
                message={
                    data?.user?.isVacationMode
                        ? `Deactivate Vacation Mode. Are you sure you  want to enable?`
                        : 'Activating vacation mode will prevent you from receiving new review assignments. Are you sure you  want to enable?'
                }
            />
            <Box
                width={width}
                background={bg}
                as="nav"
                zIndex="sticky"
                h="100vh"
                borderColor={borderColor}
                borderRightWidth="1px"
                position="fixed"
                {...rest}
            >
                <Flex
                    justify="space-between"
                    direction="column"
                    height="100%"
                    width={width}
                >
                    <Flex
                        cursor="pointer"
                        align="center"
                        justify={isExpanded ? '' : 'center'}
                        gap="4"
                        p={4}
                        to={ROUTES.HOME}
                        as={Link}
                        style={{ textDecoration: 'none' }}
                        transition=".15s ease"
                    >
                        <Logo h="50px" />
                        {isExpanded && (
                            <SimpleGrid>
                                <Text fontWeight="bold" fontSize="sm">
                                    Primal Journals
                                </Text>
                                <Text fontSize="xs">
                                    {normalize_property_name(
                                        roleState?.activeRole
                                    )}
                                </Text>
                            </SimpleGrid>
                        )}
                    </Flex>
                    <Flex
                        marginTop={8}
                        height="100%"
                        direction="column"
                        as="nav"
                        fontSize="sm"
                        color="gray.600"
                        aria-label="Main Navigation"
                        px={4}
                        gap={4}
                        position={'relative'}
                    >
                        {roleState &&
                            menus[roleState.activeRole].map((m, i) => (
                                <NavItem
                                    to={m.route}
                                    icon={m.icon}
                                    key={i}
                                    subMenus={m.subMenus}
                                    handler={m.handler}
                                >
                                    {m.label}
                                </NavItem>
                            ))}

                        {(roleState?.activeRole === 'Reviewer' ||
                            roleState?.activeRole === 'Editor') && (
                            <Button
                                gap={2}
                                position={'absolute'}
                                bottom={'4rem'}
                                justifyContent={
                                    isExpanded || isActivating
                                        ? 'flex-start'
                                        : 'center'
                                }
                                transition=".3s ease"
                                style={{
                                    width:
                                        isExpanded && !isMobile ? '85%' : '55%',
                                }}
                                rounded={'md'}
                                color={
                                    data?.user?.isVacationMode ? '#38B2AC' : ''
                                }
                                alignItems={!isExpanded ? 'center' : ''}
                                py={2}
                                px={isExpanded && !isMobile ? 4 : 1}
                                _hover={{
                                    background: '#38B2AC',
                                    color: colors.white,
                                    cursor: 'pointer',
                                }}
                                isLoading={isActivating}
                            >
                                <Flex
                                    gap={2}
                                    alignItems={'center'}
                                    onClick={() =>
                                        setShowVacationModal(!showVacationModal)
                                    }
                                >
                                    <IoAirplaneOutline
                                        size={
                                            !isExpanded && !isMobile
                                                ? '20'
                                                : '18'
                                        }
                                    />

                                    <Text
                                        fontWeight={'bold'}
                                        display={{
                                            base:
                                                !isExpanded && !isMobile
                                                    ? 'none'
                                                    : '',
                                        }}
                                    >
                                        Vacation Mode
                                    </Text>
                                </Flex>
                            </Button>
                        )}

                        {!pathname.includes('/settings') && (
                            <Flex
                                as={Link}
                                to={ROUTES.PERSONAL_INFORMATION_SETTINGS}
                                position={'absolute'}
                                bottom={2}
                                rounded={'md'}
                                alignItems={'center'}
                                py={2}
                                px={isExpanded && !isMobile ? 4 : 1}
                                gap={2}
                                bg={
                                    pathname.includes('/settings')
                                        ? 'hoverColor'
                                        : 'ghost'
                                }
                                fontWeight={
                                    pathname.includes('/settings') ? 'bold' : ''
                                }
                                color={
                                    pathname.includes('/settings')
                                        ? black
                                        : ghostGray
                                }
                                _hover={{
                                    bg: 'hoverColor',
                                }}
                                justify={isExpanded ? 'flex-start' : 'center'}
                                transition=".3s ease"
                                style={{
                                    width:
                                        isExpanded && !isMobile ? '85%' : '55%',
                                }}
                            >
                                <FiSettings
                                    size={isExpanded && !isMobile ? '20' : '18'}
                                />
                                <Text
                                    display={{
                                        base:
                                            !isExpanded && !isMobile
                                                ? 'none'
                                                : '',
                                    }}
                                >
                                    Settings
                                </Text>
                            </Flex>
                        )}
                    </Flex>

                    <Popover
                        placement="top-start"
                        initialFocusRef={initialFocusRef}
                        isOpen={isOpen}
                        onClose={close}
                        onOpen={open}
                        closeOnBlur={true}
                    >
                        <PopoverTrigger>
                            <Flex
                                cursor="pointer"
                                alignItems="start"
                                gap="2"
                                borderTopWidth="1px"
                                borderColor={borderColor}
                                p={4}
                                marginBottom={['5vh', 0]}
                            >
                                <Avatar
                                    size="sm"
                                    borderRadius="lg"
                                    src={data?.user?.profilePhoto}
                                    name={data?.user?.firstName}
                                />
                                {isExpanded && (
                                    <SimpleGrid>
                                        <Text
                                            fontSize="sm"
                                            fontWeight="bold"
                                            maxWidth={'90%'}
                                        >
                                            {data?.user?.firstName}{' '}
                                            {data?.user?.lastName}
                                        </Text>
                                        <Text fontSize="xs" minWidth={'99.5%'}>
                                            {data?.email}
                                        </Text>
                                    </SimpleGrid>
                                )}
                            </Flex>
                        </PopoverTrigger>
                        <Portal>
                            <PopoverContent
                                ml={4}
                                maxW="230px"
                                overflow="hidden"
                                _focusVisible={{
                                    outline: 'none',
                                    border: '1px solid',
                                    borderColor: colors.borderColor,
                                }}
                                border="1px solid"
                                boxShadow={shadows.default}
                                borderColor={colors.borderColor}
                            >
                                <Box
                                    rounded="lg"
                                    overflow="hidden"
                                    bottom={'1rem'}
                                >
                                    <SideNavProfilePopup close={close} />
                                </Box>
                            </PopoverContent>
                        </Portal>
                    </Popover>
                </Flex>
            </Box>
        </>
    );
};

export default Sidebar;
