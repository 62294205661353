import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Flex,
    IconButton,
    useColorMode,
    useToast,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { useNavExpanded } from '../../lib/utils';
import {
    useAuth,
    useUserRoles,
} from '../../services/authentication/auth.queries';
import { colors } from '../../styles/colors';
import Sidebar from '../Navigation/SideBar';
import { MENUS } from '../../routes/menu.navigation';
import { useSendEmailVerification } from '../../services/authentication/auth.mutation';

export default function AppWrapper({
    children,
}: {
    children: React.ReactNode;
}) {
    const { data: auth } = useAuth();
    const { colorMode } = useColorMode();
    const { expandedState, setExpanded } = useNavExpanded();
    const { roleState } = useUserRoles();
    const { mutateAsync: sendEmailVerification } = useSendEmailVerification();
    const toast = useToast();

    const canShowSideBar = useMemo(() => {
        if (roleState?.activeRole === 'Author') {
            return auth?.user?.basicInformationCompleted;
        }

        return true;
    }, [auth, roleState]);

    const onSendEmailVerification = async () => {
        await sendEmailVerification();
        toast({
            title: 'Email Verification Sent',
            description:
                'Please check your email to complete the verification process.',
            status: 'success',
            duration: 9000,
            isClosable: true,
        });
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            as="section"
            minH="100vh"
            bg={colorMode === 'light' ? colors.gray : ''}
            width="100vw"
            overflowX="hidden"
        >
            {canShowSideBar && (
                <>
                    <Sidebar
                        transition=".3s ease"
                        width={expandedState ? '240px' : '65px'}
                        display={{ base: 'none', md: 'unset' }}
                        menus={MENUS}
                    />
                </>
            )}

            <IconButton
                display={{ base: 'none', md: 'flex' }}
                ml={expandedState ? '226px' : '52px'}
                bg={colorMode === 'dark' ? 'hoverColor' : 'lightPrimary'}
                position="fixed"
                icon={expandedState ? <FaCaretLeft /> : <FaCaretRight />}
                aria-label="caret"
                rounded="full"
                size="xs"
                zIndex={1111}
                top="50%"
                onClick={() => setExpanded(!expandedState)}
            />

            <Box
                transition=".3s ease"
                width="100%"
                margin={0}
                marginInline={0}
                paddingInlineStart={{
                    base: 0,
                    md: expandedState ? '240px' : '65px',
                }}
            >
                <Box
                    as="main"
                    marginInlineStart={0}
                    height="100vh"
                    overflowY="auto"
                >
                    {!auth?.emailVerified && (
                        <Alert status="warning" flexWrap="wrap">
                            <Flex align="center">
                                <AlertIcon />
                                Please verify your email to confirm your
                                identity.
                            </Flex>
                            <Button
                                onClick={onSendEmailVerification}
                                marginLeft={['4', 0]}
                            >
                                Resend Verification Email
                            </Button>
                        </Alert>
                    )}

                    {children}
                </Box>
            </Box>
        </Box>
    );
}
