import { ChakraStylesConfig } from 'chakra-react-select';
import { colors } from '../styles/colors';
import {
    T_ADVERTISEMENT_PACKAGE_TYPE,
    T_MANUSCRIPT_STATUS,
} from '@primaljournal/commons';
import { T_ISSUE_STATUS } from '../services/issue/issue.constant';
import { T_REVIEW_REQUEST_STATUS } from '../services/review-request/review-request.constant';

export const datePickerTimeIntervals = 15;
export const DURATIONS = {
    tenSeconds: 1000 * 60 * 0.1,
    fifteenSeconds: 1000 * 60 * 0.2,
    thirtySeconds: 1000 * 60 * 0.5,
    oneMinute: 1000 * 60 * 1,
    twoMins: 1000 * 60 * 2,
    fiveMins: 1000 * 60 * 5,
    tenMins: 1000 * 60 * 10,
    fifteenMins: 1000 * 60 * 15,
    thirtyMins: 1000 * 60 * 30,
    oneHour: 1000 * 60 * 60,
    fullDay: 86400000,
};

export const generateRandomUUID = () => crypto.randomUUID();

export const chakraReactStyles: ChakraStylesConfig = {
    placeholder: (provided) => ({
        ...provided,
        color: colors.black,
        opacity: 0.4,
        fontSize: '0.9rem',
    }),
    control: (provided) => ({
        ...provided,
        background: colors.secondary,
        border: 'none',
        minHeight: 12,
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        background: colors.secondary,
        p: 0,
        w: '40px',
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: 'black',
    }),
    multiValue: (provided) => ({
        ...provided,
        color: 'black',
    }),
    clearIndicator: (provided) => ({
        ...provided,
    }),
};

export const textColorMapping: {
    [key: string]: string;
} = {
    'In Review': 'white',
    Reviewed: 'white',
    Accepted: 'white',
    Declined: 'white',
    Rejected: 'white',
    Scheduled: 'black',
    'Pushed for publication': 'white',
    'Review completed': 'white',
    'Review Declined': 'white',
    Withdrawn: 'white',
    Published: 'white',
    'Modification Required': 'white',
    'Pending Approval': 'white',
    'Uploaded publication copy': 'black',
    'Publication process failed': 'white',
    'Processing failed': 'white',
    'Issue preparation process completed': 'white',
    'Issue preparation complete': 'white',
    'Initiated push for publication': 'white',
    'Publication in progress': 'white',
    Live: 'white',
    'New Changes': 'white',
    'Collaborator Added': 'white',
    'Payment Unconfirmed': 'white',
    New: 'white',
    Cancelled: 'white',
    Completed: 'white',
    'In Progress': 'white',
};

export const manuscriptStatusColorMapping: {
    [key in T_MANUSCRIPT_STATUS | T_REVIEW_REQUEST_STATUS | string]: string;
} = {
    Archived: '#A0AEC0',
    Draft: '#A0AEC0',
    Pinned: '#A0AEC0',
    Processing: '#DACF5F',
    'New Submission': '#9AE6B4',
    'Re-Submission': '#38A169',
    Assigned: '#38B2AC',
    // 'Pending Review': '#FBD38D',
    // Reviewed: '#038600',
    'Payment Pending': '#F56565',
    'Payment Accepted': '#9AE6B4',
    // 'Plagiarism Details': '#9AE6B4',
    Accepted: '#4CAF50',
    'Accepted Conditionally': '#FCE93C',
    Rejected: '#F56565',
    Scheduled: '#6CBF84',
    Published: '#2F855A',
    'New Request': '#6CBF84',
    Withdrawn: '#F56565',
    'In Review': '#FF8A00',
    'Initiated publication copy upload': '#FBD38D',
    'Modification Required': '#FF8A00',
    Declined: '#EB001B',
    Completed: '#038600',
    'Action Required': '#FCE93C',
    'Uploaded publication copy': '#1976D6',
    'Preparing for publication': '#1976D2',
    'Pushed for publication': '#1976D2',
    'Review completed': '#38a169',
    'Review Declined': '#e64747',
    'Modification completed': '#FCE93C',
    'Pending Approval': '#FF8A00',
    'Approved for publication': '#6CBF84',
    'Scheduled for publication': '',
    'Processing failed': '#e64747',
    'Publication process failed': '#e64747',
    Modified: '',
    Pushed: '',
    Reviewed: '#38a169',
    'Initiated push for publication': '#38B2AC',
    'Publication in progress': '#38a169',
    'Verifying Payment': '#FF8A00',
    'Payment Unconfirmed': '#e64747',
};

export const authorManuscriptStatusColorMapping: {
    [key in
        | T_MANUSCRIPT_STATUS
        | T_ISSUE_STATUS
        | T_REVIEW_REQUEST_STATUS
        | string]: string;
} = {
    Archived: '#A0AEC0',
    Draft: '#A0AEC0',
    Pinned: '#A0AEC0',
    Processing: '#DACF5F',
    'New Submission': '#9AE6B4',
    'Re-Submission': '#38A169',
    Assigned: '#38B2AC',
    // 'Pending Review': '#FBD38D',
    // Reviewed: '#038600',
    'Payment Pending': '#F56565',
    'Payment Accepted': '#9AE6B4',
    // 'Plagiarism Details': '#9AE6B4',
    Accepted: '#4CAF50',
    'Accepted Conditionally': '#FCE93C',
    Rejected: '#F56565',
    Scheduled: '#6CBF84',
    Published: '#2F855A',
    // 'New Request': '#6CBF84',
    Withdrawn: '#EB001B',
    'In Review': '#FF8A00',
    // Declined: '#EB001B',
    // Completed: '#038600',
    'Preparing for publication': '#1976D2',
    'Pushed for publication': '#1976D2',
    'Review completed': '#FF8A00',
    'Review Declined': '#FF8A00',
    'Action Required': '#FCE93C',
    'Scheduled for publication': '',
    'Modification Required': '#FF8A00',
    Modified: '',
    'Modification completed': '#6CBF84',
    'Pending Approval': '#FF8A00',
    'Approved for publication': '#6CBF84',
    'Uploaded publication copy': '#1976D6',
    'Publication process failed': '#1976D6',
    'Processing failed': '#e64747',
    'Initiated publication copy upload': '#1976D2',
    'Verifying Payment': '#FF8A00',
    'Payment Unconfirmed': '#e64747',
};

export const collaborationStatusMapping: Record<string, string> = {
    ...manuscriptStatusColorMapping,
    Live: '#38A169',
    Rejected: '#EB001B',
    'New Changes': '#FF8A00',
    'Collaborator Added': '#1976D2',
};

export const issueStatusColorMapping: Record<string, string> = {
    ...manuscriptStatusColorMapping,
    'Publication process failed': '#e64747',
    'Initiated publication copy upload': '#FBD38D',
    'Initiated push for publication': '#FBD38D',
    'Issue preparation process failed': '#EB001B',
    'Issue preparation process completed': '#2F855A',
    'Issue preparation complete': '#2F855A',
    'Issue in preparation': '#FF8A00',
};

export const agendaStatusMapping: Record<string, string> = {
    ...manuscriptStatusColorMapping,
    New: '#1976D2',
    Completed: '#38A169',
    Cancelled: '#EB001B',
    'In Progress': '#FF8A00',
};

export const fileTypes = {
    wordOrText:
        '.txt, text/plain, .doc, .docx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    anyImage: 'image/*, image/png, image/jpeg, image/jpg',
    anyFile: '*',
    pdf: 'application/pdf',
    anyDoc: 'application/pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
};

export const type = [
    '.doc',
    '.docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const docType = [
    '.doc',
    '.docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const imageType = ['image/png', 'image/jpeg', 'image/jpg'];

export const pdfType = ['application/pdf'];

export const excelType = [
    '.xls',
    '.xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const allDocumentType = [
    '.txt, text/plain',
    'image/*',
    'image/png',
    'image/jpeg',
    'image/jpg',
    'application/pdf',
    '.doc',
    '.docx',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.xls',
    '.xlsx',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const othersTypes = [
    '.txt, text/plain',
    'image/*',
    'image/png',
    'image/jpeg',
    'image/jpg',
];

export const typeOrder: Record<T_ADVERTISEMENT_PACKAGE_TYPE, number> = {
    PREMIUM: 0,
    FEATURED: 1,
    STANDARD: 2,
};
