import {
    chakra,
    Button,
    Avatar,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Portal,
    IconButton,
    Box,
    Flex,
    Tooltip,
    useBoolean,
    useEventListener,
    HStack,
    Text,
} from '@chakra-ui/react';
import React, { useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AUTH_NAV_ITEMS, PUBLIC_NAV_ITEMS, ROUTES } from '../../App.constant';
import { Logo } from '../Logo/Logo';
import { useAuth } from '../../services/authentication/auth.queries';
import { SideNavProfilePopup } from '../Navigation/SideNavProfilePopup';
import { colors } from '../../styles/colors';
// import { BsCart3 } from 'react-icons/bs';
// import { RiNotification3Line } from 'react-icons/ri';
import PrimalMenu from '../Menu/PrimalMenu';
import { FiSearch } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import PublicSearchInput from '../Search/PublicSearchInput';
import { shadows } from '../../styles/shadow';
import AuthModal from '../modals/AuthModal';
import { IoDocumentsOutline } from 'react-icons/io5';

export const DesktopPublicHeader = () => {
    const location = useLocation();
    const { data } = useAuth();
    const initialFocusRef = useRef<any>(null);
    const [isOpen, setIsOpen] = useState(false);
    const [showAuthModal, setShowModal] = useState<boolean>(false);

    const open = () => setIsOpen(!isOpen);
    const close = () => setIsOpen(false);
    const navigate = useNavigate();
    const [isScrolling, setFlag] = useBoolean(window.pageYOffset > 200);
    useEventListener('scroll', () => {
        const isScrolling = window.pageYOffset > 200;
        if (isScrolling) {
            setFlag.on();
        } else {
            setFlag.off();
        }
    });

    const isSearchPageActive = useMemo(() => {
        return location.pathname === ROUTES.SEARCH_PAGE;
    }, [location.pathname]);

    return (
        <>
            <AuthModal
                setShowAuthModal={setShowModal}
                showAuthModal={showAuthModal}
                isCreatingManuscript={false}
            />

            <chakra.div position="relative" px={4} py={4}>
                <Flex
                    w="full"
                    alignItems="center"
                    justifyContent="space-between"
                    gap={4}
                >
                    <Flex
                        gap={4}
                        align="center"
                        w="100%"
                        alignItems={'center'}
                        alignContent="center"
                    >
                        <Flex to={ROUTES.HOME} as={Link} gap={4} align="center">
                            <Logo h={45} transition="height 0.2s ease-in" />
                            <Text
                                fontWeight="extrabold"
                                fontSize="lg"
                                whiteSpace="nowrap"
                            >
                                Primal Journals
                            </Text>
                        </Flex>

                        <HStack w="100%" ml={{ md: 6 }}>
                            {PUBLIC_NAV_ITEMS.map((n, i) => {
                                return (
                                    <PrimalMenu
                                        padding={2}
                                        variant="alternateDark"
                                        key={i}
                                        href={n.href}
                                        label={n.label}
                                        SubMenuComponent={n.SubMenuComponent}
                                    />
                                );
                            })}

                            {location.pathname !== '/' && !data && (
                                <Button
                                    px={4}
                                    py={3}
                                    height={'100%'}
                                    background={colors.primary}
                                    color={colors.white}
                                    gap={2}
                                    onClick={() => {
                                        if (!data) {
                                            setShowModal(true);
                                        } else {
                                            navigate(ROUTES.DASHBOARD);
                                        }
                                    }}
                                    fontSize=".8em"
                                    ml={6}
                                    display={'flex'}
                                    alignItems={'center'}
                                >
                                    <IoDocumentsOutline />
                                    Go to dashbord
                                </Button>
                            )}

                            {/* {PUBLIC_NAV_ITEMS.map((n, i) => {
                            if (n.isButton) {
                                return (
                                    <Button
                                        key={i}
                                        px={4}
                                        py={2}
                                        height={'max'}
                                        background={colors.primary}
                                        color={colors.white}
                                        as={Link}
                                        to={ROUTES.DASHBOARD}
                                        fontSize={'sm'}
                                    >
                                        {n.label}
                                    </Button>
                                );
                            } else {
                                return (
                                    <PrimalMenu
                                        padding={2}
                                        variant="alternateDark"
                                        key={i}
                                        href={n.href}
                                        label={n.label}
                                        SubMenuComponent={n.SubMenuComponent}
                                    />
                                );
                            }
                        })} */}
                        </HStack>
                    </Flex>

                    <Flex w="100%" justify="flex-end" align="center">
                        {!isSearchPageActive && (
                            <PublicSearchInput
                                bg={isScrolling ? colors.white : colors.white}
                                display={{ base: 'none', xl: 'block' }}
                                matchWidth={true}
                                placement="bottom-start"
                            />
                        )}

                        <Flex mx={2}>
                            <Tooltip
                                label="Search"
                                openDelay={500}
                                placement="bottom"
                                bg={colors.lightPrimary}
                                color={colors.black}
                                borderRadius={4}
                            >
                                <IconButton
                                    display={{ base: 'flex', xl: 'none' }}
                                    variant="ghost"
                                    color="current"
                                    onClick={() => navigate(ROUTES.SEARCH_PAGE)}
                                    icon={<FiSearch />}
                                    aria-label="Search journals, authors, affiliates"
                                    fontSize="20px"
                                />
                            </Tooltip>
                            {data && (
                                <>
                                    {/* <Tooltip
                                    label="Notifications"
                                    openDelay={500}
                                    placement="bottom"
                                    bg={colors.lightPrimary}
                                    color={colors.black}
                                    borderRadius={4}
                                >
                                    <IconButton
                                        variant="ghost"
                                        color="current"
                                        onClick={() =>
                                            navigate(ROUTES.NOTIFICATIONS)
                                        }
                                        icon={<RiNotification3Line />}
                                        aria-label="My Notifications"
                                        fontSize="20px"
                                    />
                                </Tooltip>

                                <Tooltip
                                    label="Cart"
                                    openDelay={500}
                                    placement="bottom"
                                    bg={colors.lightPrimary}
                                    color={colors.black}
                                    borderRadius={4}
                                >
                                    <IconButton
                                        variant="ghost"
                                        color="current"
                                        onClick={() => navigate(ROUTES.CART)}
                                        icon={<BsCart3 />}
                                        aria-label="My Cart"
                                        fontSize="20px"
                                    />
                                </Tooltip> */}
                                </>
                            )}
                        </Flex>

                        <>
                            {data ? (
                                <Flex alignItems={'center'} gap={2}>
                                    <Button
                                        px={4}
                                        py={2}
                                        height={'max'}
                                        background={colors.primary}
                                        color={colors.white}
                                        as={Link}
                                        to={ROUTES.DASHBOARD}
                                        fontSize={'sm'}
                                        ml={6}
                                    >
                                        Dashboard
                                    </Button>

                                    <Popover
                                        placement="bottom-end"
                                        initialFocusRef={initialFocusRef}
                                        isOpen={isOpen}
                                        onClose={close}
                                        onOpen={open}
                                        closeOnBlur={true}
                                    >
                                        <PopoverTrigger>
                                            <Avatar
                                                size="sm"
                                                borderRadius="lg"
                                                src={data?.user?.profilePhoto}
                                                name={data?.user?.firstName}
                                                cursor="pointer"
                                            />
                                        </PopoverTrigger>
                                        <Portal>
                                            <PopoverContent
                                                maxW="230px"
                                                rounded="lg"
                                                overflow="hidden"
                                                _focusVisible={{
                                                    outline: 'none',
                                                    border: '1px solid',
                                                    borderColor:
                                                        colors.borderColor,
                                                }}
                                                border="1px solid"
                                                boxShadow={shadows.default}
                                                borderColor={colors.borderColor}
                                            >
                                                <Box
                                                    rounded="lg"
                                                    overflow="hidden"
                                                >
                                                    <SideNavProfilePopup />
                                                </Box>
                                            </PopoverContent>
                                        </Portal>
                                    </Popover>
                                </Flex>
                            ) : (
                                <Flex gap={2} ml={4} alignItems={'center'}>
                                    {AUTH_NAV_ITEMS.map((navItem, i) => (
                                        <Button
                                            key={i}
                                            to={navItem.href}
                                            as={Link}
                                            variant={navItem.variant}
                                            fontSize=".8em"
                                            aria-label={navItem.ariaLabel}
                                        >
                                            {navItem.label}
                                        </Button>
                                    ))}
                                </Flex>
                            )}
                        </>
                    </Flex>
                </Flex>
            </chakra.div>
        </>
    );
};
