import { IAuth } from '@primaljournal/commons';
import { useUserRoles } from '../services/authentication/auth.queries';
import AdminRoute from './admin.route';
import AuthorRoute from './author.route';
import ChiefEditorRoute from './chiefEditor.route';
import EditorRoute from './editor.route';
import EditorialSecretaryRoute from './editorialSecretary.route';
import ManagerialEditorRoute from './managerialEditor.route';
import ReviewerRoute from './reviewer.route';
import IRoute from './route.interface';

const InternalRoutes = (auth: IAuth | undefined): IRoute[] => {
    const { roleState } = useUserRoles();

    if (!auth) return [];

    if (roleState?.activeRole === 'Author') return AuthorRoute(auth);
    if (roleState?.activeRole === 'Admin') return AdminRoute();
    if (roleState?.activeRole === 'ChiefEditor') return ChiefEditorRoute();
    if (roleState?.activeRole === 'ManagerialEditor')
        return ManagerialEditorRoute();
    if (roleState?.activeRole === 'EditorialSecretary')
        return EditorialSecretaryRoute();
    if (roleState?.activeRole === 'Editor') return EditorRoute();
    if (roleState?.activeRole === 'Reviewer') return ReviewerRoute();

    return [];
};

export default InternalRoutes;
