import { chakra, ImageProps, forwardRef, useColorMode } from '@chakra-ui/react';
import logoDark from './logoDark.svg';
import logoLight from './logoLight.svg';

export const Logo = forwardRef<ImageProps, 'img'>((props, ref) => {
  const { colorMode } = useColorMode();

  if (colorMode === 'dark') {
    return <chakra.img src={logoDark} ref={ref} {...props} />;
  } else {
    return <chakra.img src={logoLight} ref={ref} {...props} />;
  }
});
