import { Variants } from 'framer-motion';
import { useQuery, useQueryClient } from 'react-query';
import { useLocalStorage } from '../hooks/useLocalStorage';
import {
    format,
    isToday,
    isYesterday,
    isThisYear,
    isThisWeek,
    isThisMonth,
} from 'date-fns';

export function isDev() {
    return process.env.NODE_ENV === 'development';
}

export const supportedImageFormats = ['png', 'jpeg', 'jpg'];
export const isImageValid = (image: string) => {
    const extension = image.split('.').pop();

    if (extension && !supportedImageFormats.includes(extension)) {
        return false;
    }
    return true;
};

export function deviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
        return 'tablet';
    } else if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
        return 'mobile';
    }
    return 'desktop';
}

export const ANIMATION_PRESENCE_VARIANT: Variants = {
    hidden: { y: -20, opacity: 0 },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.3,
            ease: 'easeInOut',
        },
    },
    exit: {
        y: 200,
        opacity: 0,
        transition: {
            ease: 'easeInOut',
            duration: 0.3,
        },
    },
};

export const utilsQueryKeys = {
    all: [{ scope: 'utils' }] as const,
    sideNavExpanded: () =>
        [{ ...utilsQueryKeys.all[0], entity: 'navExpanded' }] as const,
};

export const useNavExpanded = () => {
    const queryClient = useQueryClient();
    const { getValue, setValue } = useLocalStorage();

    const { data: expandedState } = useQuery<boolean | null>(
        utilsQueryKeys.sideNavExpanded(),
        () => getValue('sideNavExpanded', true)
    );

    const setExpanded = (state: boolean) => {
        queryClient.setQueryData<boolean>(
            utilsQueryKeys.sideNavExpanded(),
            () => {
                setValue('sideNavExpanded', state);
                return state;
            }
        );
    };

    return { expandedState, setExpanded };
};

export const getFileNameFromUrl = (url: string | undefined) => {
    if (!url) return '';
    const extension = url.substring(url.lastIndexOf('.'));
    return `${url.substring(
        url.lastIndexOf('/') + 1,
        url.lastIndexOf('.')
    )}${extension}`;
};

export const LightenDarkenColor = (col: any, amt: number) => {
    let usePound = false;

    if (col[0] === '#') {
        col = col.slice(1);
        usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

export function formatDate(date: Date): string {
    if (isToday(date)) {
        return 'Today';
    } else if (isYesterday(date)) {
        return 'Yesterday';
    } else if (isThisWeek(date)) {
        // If the date is within this week, format it as 'EEEE'
        // which represents the day of the week (e.g., 'Monday')
        return format(date, 'EEEE');
    } else if (isThisMonth(date)) {
        // If the date is within this month, format it as 'MMMM d'
        // (e.g., 'January 1')
        return format(date, 'MMMM d');
    } else if (isThisYear(date)) {
        // If the date is within this year, format it as 'MMMM'
        // which represents the month (e.g., 'January')
        return format(date, 'MMMM');
    } else {
        // If the date is from a previous year,
        // format it as 'MMMM d, yyyy' (e.g., 'January 1, 2020')
        return format(date, 'MMMM d, yyyy');
    }
}

export function ensureHttps(url: string) {
    const hasHttps = /^https:\/\//i.test(url);
    const hasHttp = /^http:\/\//i.test(url);

    if (!hasHttps) {
        if (hasHttp) {
            url = url.replace(/^http:\/\//i, 'https://');
        } else {
            url = 'https://' + url;
        }
    }

    return url;
}

export const sentenceCase = (str: string) => {
    if (!str) return '';

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export function sentenceCaseEachWord(str: string) {
    return str
        .split(' ')
        .map(
            (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(' ');
}

export function splitAmountByThousands(amount: string | number) {
    const parts = [];
    let remaining = amount.toString();

    while (remaining.length > 3) {
        parts.unshift(remaining.slice(-3));
        remaining = remaining.slice(0, -3);
    }

    if (remaining.length > 0) {
        parts.unshift(remaining);
    }

    return parts.join(',');
}


export const isSnap = Boolean(process.env.REACT_SNAP);
