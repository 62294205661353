import { useLocation, useNavigate } from 'react-router';
import { useVerifyEmail } from '../../services/authentication/auth.mutation';
import {
    Box,
    Button,
    Container,
    Heading,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react';
import { colors } from '../../styles/colors';
import { ROUTES } from '../../App.constant';

const EmailVerification = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const token = params.get('token');

    const { mutateAsync: verifyEmail } = useVerifyEmail();
    const toast = useToast();

    const onVerifyEmail = async () => {
        try {
            if (token) {
                await verifyEmail(token);
                toast({
                    title: 'Email Verification Successful',
                    description: 'Your email has been successfully verified.',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                });
                setTimeout(() => {
                    navigate(ROUTES.HOME);
                }, 1000);
            }
        } catch (error) {}
    };

    return (
        <Container maxW={'3xl'}>
            <Stack
                as={Box}
                textAlign={'center'}
                spacing={{ base: 8 }}
                py={{ base: 20, md: 36 }}
            >
                <Heading fontWeight={600} fontSize={{ base: '2xl', sm: '4xl' }}>
                    <Text as={'span'}>Email Verification</Text>
                </Heading>
                <Text color={'gray.500'}>
                    Thank you for signing up with Primal Journals. To complete
                    the registration process, please click the button below to
                    verify your email address.
                </Text>
                <Stack
                    direction={'column'}
                    spacing={3}
                    align={'center'}
                    alignSelf={'center'}
                    position={'relative'}
                >
                    <Button
                        onClick={onVerifyEmail}
                        bg={colors.primary}
                        rounded={'full'}
                        px={6}
                        color="white"
                    >
                        Verify Email
                    </Button>
                </Stack>
            </Stack>
        </Container>
    );
};

export default EmailVerification;
