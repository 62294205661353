import { IManuscript } from '@primaljournal/commons';

export const colors = {
    primary: '#899878',
    lightPrimary: '#e2e4db',
    hoverColor: 'rgba(137, 152, 120, 0.2)',
    secondary: '#E4E6C3',
    gray: '#F7f7f2',
    black: '#222725',
    ghostGray: '#B0B0B0',
    white: '#fff',
    borderColor: '#e2e4dbc2',
    transparent: '',
};

export const colorsRepo = {
    primary: '#899878',
    lightPrimary: '#e2e4db',
    hoverColor: 'rgba(137, 152, 120, 0.2)',
    secondary: '#E4E6C3',
    gray: '#F7f7f2',
    black: '#222725',
    ghostGray: '#B0B0B0',
    white: '#fff',
    borderColorDark: '#e2e4db36',
    borderColorLight: '#e2e4dbc2',
};

interface IDynamicLightDarkColorConfig {
    light: IDynamicLightDarkColorConfigMode;
    dark: IDynamicLightDarkColorConfigMode;
}

type IDynamicLightDarkColorConfigMode = {
    [key in keyof typeof colors]: string;
};

export const dynamicLightDarkColorConfig: IDynamicLightDarkColorConfig = {
    light: {
        ...colors,
        black: colors.black,
        white: colors.white,
        ghostGray: colors.ghostGray,
        gray: colors.gray,
        primary: colors.primary,
        secondary: colors.secondary,
        hoverColor: colors.hoverColor,
        lightPrimary: colors.lightPrimary,
        borderColor: colorsRepo.borderColorLight,
    },
    dark: {
        ...colors,
        black: colors.white,
        white: colors.black,
        ghostGray: colors.ghostGray,
        gray: colors.black,
        primary: colors.primary,
        secondary: colors.secondary,
        hoverColor: colors.hoverColor,
        lightPrimary: colors.hoverColor,
        borderColor: colorsRepo.borderColorDark,
    },
};

export const publicationTypeColor = (type: IManuscript['publicationType']) => {
    const typeMaps: Record<
        IManuscript['publicationType'],
        { background: string; color: string }
    > = {
        ARTICLE: {
            background: colors.lightPrimary,
            color: '',
        },
        PAPER: {
            background: colors.secondary,
            color: '',
        },
    };

    return typeMaps[type];
};
