import { Route, Routes } from 'react-router-dom';
import { PublicAppWrapper } from '../components/AppWrappers.tsx/PublicAppWrapper';
import { useAuth } from '../services/authentication/auth.queries';
import InternalRoutes from './internal.route';
import PublicRoutes from './public.route';
import AuthenticationRoutes from './authentication.route';
import { withErrorBoundary } from '../lib/HOCs/withErrorBoundary';
import { withSuspense } from '../lib/HOCs/withSuspense';

const RouteController = () => {
    const { data: auth } = useAuth();

    return (
        <Routes>
            {PublicRoutes().map(({ element: Element, path }, i) => {
                const LazyRoute = withErrorBoundary(
                    withSuspense(Element, {
                        fallBackComponent: <div />,
                        wrapper: PublicAppWrapper,
                    })
                );

                return (
                    <Route key={path + i} element={<LazyRoute />} path={path} />
                );
            })}

            {auth ? (
                <>
                    {InternalRoutes(auth).map(
                        ({ element: Element, path, wrapper: Wrapper }, i) => {
                            return (
                                <Route
                                    key={path + i}
                                    element={
                                        Wrapper ? (
                                            <Wrapper>
                                                <Element />
                                            </Wrapper>
                                        ) : (
                                            <Element />
                                        )
                                    }
                                    path={path}
                                />
                            );
                        }
                    )}
                </>
            ) : (
                <>
                    {AuthenticationRoutes().map(
                        ({ element: Element, path }, i) => {
                            return (
                                <Route
                                    key={path + i}
                                    element={<Element />}
                                    path={path}
                                />
                            );
                        }
                    )}
                </>
            )}
        </Routes>
    );
};

export default RouteController;
