import React from 'react';
import { Stack } from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { ANIMATION_PRESENCE_VARIANT } from '../../lib/utils';
import PublicFooter from '../AppBar/PublicFooter';
import { PublicHeader } from '../AppBar/PublicHeader';
import { AppMain } from '../AppBar/AppMain';

export const PublicAppWrapper = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    return (
        <AnimatePresence exitBeforeEnter>
            <motion.div
                variants={ANIMATION_PRESENCE_VARIANT}
                exit="exit"
                initial="hidden"
                animate="visible"
            >
                <Stack
                    w="100%"
                    m={0}
                    p={0}
                    position="relative"
                    minHeight={'100vh'}
                >
                    <PublicHeader />
                    <Stack
                        overflow="auto"
                        margin={0}
                        marginTop={0}
                        style={{ marginTop: 0 }}
                        minHeight={'100vh'}
                    >
                        <AppMain>{children}</AppMain>
                        <PublicFooter />
                    </Stack>
                </Stack>
            </motion.div>
        </AnimatePresence>
    );
};
