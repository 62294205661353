import { Button, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { BiHome } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../App.constant';
import { colors } from '../../../styles/colors';

const Error404 = () => {
    window.scroll(0, 0);
    return (
        <Stack
            minHeight={'30rem'}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Text fontWeight={800} fontSize={'4rem'}>
                404
            </Text>

            <Text>Not Found.</Text>

            <Button
                p={4}
                width={'max-content'}
                display={'flex'}
                alignItems={'center'}
                gap={2}
                as={Link}
                to={ROUTES.HOME}
                border={'none'}
                outline={'none'}
                borderRadius={'none'}
                _hover={{ borderBottom: `2px solid ${colors.primary}` }}
            >
                Return Home <BiHome />
            </Button>
        </Stack>
    );
};

export default Error404;
