import { Divider, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { colors } from '../../../styles/colors';
import { ROUTES } from '../../../App.constant';
import { BsArrow90DegRight } from 'react-icons/bs';
import './JournalMenuSubMenu.css';

interface IProp {
    onClose: () => void;
}

const JournalMenuSubMenu = ({ onClose }: IProp) => {
    return (
        <Stack p={4} gap={2} maxH={600} overflowY="auto" w="100%">
            {staticJournals.map((j, i) => (
                <div
                    className="menu-submenu-item"
                    key={i}
                    onClick={() => onClose()}
                >
                    <Stack
                        w="100%"
                        direction="row"
                        as={Link}
                        to={`${ROUTES.JOURNALS}/${j.slug}`}
                        align="center"
                    >
                        <Stack>
                            <Text fontSize="md" fontWeight="bold">
                                {j.name}
                            </Text>
                            <Text
                                fontSize="xs"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                display="-webkit-box"
                                style={{
                                    WebkitLineClamp: 3,
                                    WebkitBoxOrient: 'vertical',
                                }}
                            >
                                {j.metaDescription}
                            </Text>
                        </Stack>
                        <div className="arrow-icon">
                            <BsArrow90DegRight color={colors.primary} />
                        </div>
                    </Stack>
                </div>
            ))}

            <Divider bg={colors.borderColor} />

            <div className="menu-submenu-item" onClick={() => onClose()}>
                <Stack
                    direction="row"
                    as={Link}
                    to={`${ROUTES.JOURNALS}?page=1`}
                    align="center"
                >
                    <Stack>
                        <Text fontSize="md" fontWeight="bold">
                            Browse all journal categories
                        </Text>
                        <Text fontSize="xs">
                            Explore our complete collection of journals and find
                            the right one for your interests and expertise.
                        </Text>
                    </Stack>

                    <div className="arrow-icon">
                        <BsArrow90DegRight color={colors.primary} />
                    </div>
                </Stack>
            </div>
        </Stack>
    );
};

export default JournalMenuSubMenu;

const staticJournals = [
    {
        name: 'Accounting, Budgeting and Financial Management',
        coverImage:
            'https://primal-assets.s3.amazonaws.com/03763705939107265-Primal%20Journal%2C%20Accounting%2C%20Budgeting%20and%20Financial%20Management.jpg',
        email: 'PJ.ABF@primaljournals.org',
        shortName: 'ABF',
        slug: 'Accounting-Budgeting-and-Financial-Management-ABF',
        metaDescription:
            'Discover research on accounting, budgeting, & financial management in the Journal of Accounting by Primal Journals. Gain insights for informed decision-making.',
    },
    {
        name: 'African Beliefs, Culture and Traditions',
        shortName: 'ABCT',
        coverImage:
            'https://primal-assets.s3.amazonaws.com/23548921290915947-Primal%20Journals%20of%20African%20Beliefs%2C%20Culture%20and%20Traditions.jpg',
        email: 'PJ.ABCT@primaljournals.org',
        reviewForm:
            'https://primal-documents.s3.amazonaws.com/08656153395084254-PRIMAL%20REVIEWER%20FORM%2C%20African%20Beliefs%2C%20Culture%20and%20Traditions.docx',
        slug: 'African-Beliefs-Culture-and-Traditions-ABCT',
        metaDescription:
            'Explore African cultures and traditions in the peer-reviewed Journal of African Beliefs, Culture, and Traditions. Uncover new insights and interdisciplinary connections.',
    },
    {
        name: 'AI, Computer Science and Robotics',
        coverImage:
            'https://primal-assets.s3.amazonaws.com/33546402163115596-Primal%20Journals%20of%20AI%2C%20Computer%20Science%20and%20Robotics.jpg',
        email: 'PJ.ACR@primaljournals.org',
        shortName: 'ACR',
        slug: 'AI-Computer-Science-and-Robotics-ACR',
        metaDescription:
            'Journal of AI, Computer Science, and Robotics publishes original research on AI, CS, and robotics. Explore cutting-edge findings & integration with other disciplines.',
    },
];
