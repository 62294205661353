import { apiHttp } from './../../lib/appConfig';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { UserRoleState } from './auth.interface';
import { ServerResponse } from '../../hooks/useAxiosConfig';
import { DURATIONS } from '../../lib/constants';
import { localStorageKeys, useLocalStorage } from '../../hooks/useLocalStorage';
import { IAuth } from '@primaljournal/commons';

export const authQueryKeys = {
    all: [{ scope: 'auth' }] as const,
    auth: () => [{ ...authQueryKeys.all[0], entity: 'auth' }] as const,
    singleAuth: (authId?: string) =>
        [
            {
                ...authQueryKeys.all[0],
                entity: 'single-auth',
                authId,
            },
        ] as const,
    role: (auth: IAuth | undefined) =>
        [{ ...authQueryKeys.all[0], entity: 'role', auth }] as const,
};

const getAuth = async () => {
    const auth = JSON.parse(
        localStorage.getItem(localStorageKeys.auth) as string
    );

    if (!auth) {
        console.log('Not Authenticated');
        return false;
    }
    const res = await apiHttp.get<ServerResponse<IAuth>>('/auth/me');

    return { ...res.data.data, accessToken: auth.accessToken };
};

// 🙋‍♀️ Get the current authenticated user
export const useAuth = <TSelectData = IAuth, Error = unknown>(
    options?: UseQueryOptions<IAuth | boolean, Error, TSelectData>
) => {
    return useQuery<IAuth | boolean, Error, TSelectData>(
        authQueryKeys.auth(),
        getAuth,
        {
            suspense: true,
            staleTime: DURATIONS.fifteenMins,
            ...options,
        }
    );
};

export const useUserRoles = () => {
    const queryClient = useQueryClient();
    const { data: auth } = useAuth();
    const { getValue, setValue } = useLocalStorage();

    // 💡 Getting the preferred initial role from URL search params
    const { data: roleState } = useQuery<UserRoleState | undefined>(
        authQueryKeys.role(auth),
        async () => {
            if (!auth) return undefined;
            const roles = auth.roles;

            // 💡 Getting the preferred initial role from localStorage
            let initialProfile = getValue('preferredProfile', roles[0]);
            // ⚡ checking if the saved role is valid
            if (initialProfile && !roles.includes(initialProfile)) {
                initialProfile = roles[0];
                setValue('preferredProfile', roles[0]);
            }

            return {
                roles,
                activeRole: initialProfile as UserRoleState['activeRole'],
            };
        },
        {
            suspense: true,
            staleTime: DURATIONS.oneHour,
            enabled: Boolean(auth),
        }
    );

    const setCurrentRole = (role: UserRoleState['activeRole']) => {
        queryClient.setQueryData<UserRoleState | undefined>(
            authQueryKeys.role(auth),
            (prev) => {
                if (prev) {
                    setValue('preferredProfile', role);
                    return { ...prev, activeRole: role };
                }
            }
        );
    };

    return { roleState, setCurrentRole };
};
