import {
    RiBook2Line,
    RiFileList3Line,
    RiInformationLine,
    RiPagesLine,
} from 'react-icons/ri';
import { IconType } from 'react-icons';
import { ROUTES } from '../App.constant';
import { BiHome, BiTransfer } from 'react-icons/bi';
import { FiSettings, FiUser, FiUsers } from 'react-icons/fi';
import { BsJournalMedical } from 'react-icons/bs';
import { MdDeliveryDining, MdOutlineNotificationsActive } from 'react-icons/md';
import { FaLock, FaRegHandshake } from 'react-icons/fa';
import { ROLES_TYPE } from '@primaljournal/commons';
import { IoMegaphoneOutline } from 'react-icons/io5';

export interface IMenuItem {
    label: string;
    icon: IconType;
    route: string;
    subMenus?: IMenuItem[];
    handler?: VoidFunction;
}
export type IMenu = {
    [key in ROLES_TYPE]: IMenuItem[];
};

export const MENUS: IMenu = {
    Author: [
        // {
        //     icon: RiDashboardLine,
        //     label: 'Dashboard',
        //     route: ROUTES.DASHBOARD,
        // },
        {
            icon: RiPagesLine,
            label: 'Manuscripts',
            route: ROUTES.MANUSCRIPTS,
        },

        {
            icon: FaRegHandshake,
            label: 'Collaborations',
            route: ROUTES.COLLABORATIONS,
        },
        {
            icon: BiTransfer,
            label: 'Transactions',
            route: ROUTES.TRANSACTIONS,
        },

        // {
        //     icon: BiLibrary,
        //     label: 'Library',
        //     route: ROUTES.LIBRARY,
        // },
    ],
    Admin: [
        {
            icon: FiUsers,
            label: 'Users',
            route: ROUTES.USERS,
        },
        {
            icon: BsJournalMedical,
            label: 'Journals',
            route: ROUTES.JOURNAL_CATEGORIES,
        },
        {
            icon: RiPagesLine,
            label: 'Manuscripts',
            route: ROUTES.MANUSCRIPTS,
        },
        {
            icon: RiBook2Line,
            label: 'Publications',
            route: ROUTES.PUBLICATIONS,
        },
        {
            icon: FaRegHandshake,
            label: 'Collaborations',
            route: ROUTES.COLLABORATIONS,
        },

        {
            icon: MdOutlineNotificationsActive,
            label: 'News and Blogs',
            route: ROUTES.BLOGS,
        },
        {
            icon: RiInformationLine,
            label: 'Ethics',
            route: ROUTES.ETHICS,
        },
        {
            icon: RiFileList3Line,
            label: 'Review Applications',
            route: ROUTES.REVIEWERS_WAIT_LIST,
        },
        {
            icon: IoMegaphoneOutline,
            label: 'Ad Packages',
            route: ROUTES.ADVERTISEMENT_PACKAGES,
        },
        {
            icon: BiTransfer,
            label: 'Payments',
            route: ROUTES.PAYMENTS,
        },
        {
            icon: FiSettings,
            label: 'Primal Assets',
            route: ROUTES.PRIMAL_SETTINGS,
        },
    ],
    Editor: [
        {
            icon: RiPagesLine,
            label: 'Review Requests',
            route: ROUTES.DASHBOARD,
        },
    ],
    Reviewer: [
        {
            icon: RiPagesLine,
            label: 'Review Requests',
            route: ROUTES.DASHBOARD,
        },
    ],
    ChiefEditor: [
        {
            icon: RiPagesLine,
            label: 'Manuscripts',
            route: ROUTES.DASHBOARD,
        },
    ],
    ManagerialEditor: [
        {
            icon: RiPagesLine,
            label: 'Manuscripts',
            route: ROUTES.MANUSCRIPTS,
        },
        {
            icon: RiBook2Line,
            label: 'Publications',
            route: ROUTES.PUBLICATIONS,
        },
    ],
    EditorialSecretary: [
        {
            icon: RiPagesLine,
            label: 'Manuscripts',
            route: ROUTES.DASHBOARD,
        },
    ],
    Blogger: [],
    Support: [],
};

export const SETTINGS_MENUS: IMenu = {
    Author: [
        {
            icon: FiUser,
            label: 'Personal Information',
            route: ROUTES.PERSONAL_INFORMATION_SETTINGS,
            subMenus: [
                // {
                //     icon: FaUniversity,
                //     label: 'Institutions',
                //     route: ROUTES.AFFILIATES_SETTINGS,
                // },
                {
                    icon: MdDeliveryDining,
                    label: 'Shipping',
                    route: ROUTES.SHIPPING_SETTINGS,
                },
                {
                    icon: FaLock,
                    label: 'Reset Password',
                    route: ROUTES.PASSWORD_SETTINGS,
                },
            ],
        },
        {
            icon: BiHome,
            label: 'Home',
            route: ROUTES.MANUSCRIPTS,
        },
    ],
    Admin: [
        {
            icon: FiUser,
            label: 'Personal Information',
            route: ROUTES.PERSONAL_INFORMATION_SETTINGS,
            subMenus: [
                // {
                //     icon: FaUniversity,
                //     label: 'Institutions',
                //     route: ROUTES.AFFILIATES_SETTINGS,
                // },
                {
                    icon: MdDeliveryDining,
                    label: 'Shipping',
                    route: ROUTES.SHIPPING_SETTINGS,
                },
                {
                    icon: FaLock,
                    label: 'Reset Password',
                    route: ROUTES.PASSWORD_SETTINGS,
                },
            ],
        },
        {
            icon: BiHome,
            label: 'Home',
            route: ROUTES.DASHBOARD,
        },
    ],
    Editor: [
        {
            icon: FiUser,
            label: 'Personal Information',
            route: ROUTES.PERSONAL_INFORMATION_SETTINGS,
            subMenus: [
                // {
                //     icon: FaUniversity,
                //     label: 'Institutions',
                //     route: ROUTES.AFFILIATES_SETTINGS,
                // },
                {
                    icon: MdDeliveryDining,
                    label: 'Shipping',
                    route: ROUTES.SHIPPING_SETTINGS,
                },
                {
                    icon: FaLock,
                    label: 'Reset Password',
                    route: ROUTES.PASSWORD_SETTINGS,
                },
            ],
        },
        {
            icon: BiHome,
            label: 'Home',
            route: ROUTES.DASHBOARD,
        },
    ],
    Reviewer: [
        {
            icon: FiUser,
            label: 'Personal Information',
            route: ROUTES.PERSONAL_INFORMATION_SETTINGS,
            subMenus: [
                // {
                //     icon: FaUniversity,
                //     label: 'Institutions',
                //     route: ROUTES.AFFILIATES_SETTINGS,
                // },
                {
                    icon: MdDeliveryDining,
                    label: 'Shipping',
                    route: ROUTES.SHIPPING_SETTINGS,
                },
                {
                    icon: FaLock,
                    label: 'Reset Password',
                    route: ROUTES.PASSWORD_SETTINGS,
                },
            ],
        },
        {
            icon: BiHome,
            label: 'Home',
            route: ROUTES.DASHBOARD,
        },
    ],
    ChiefEditor: [
        {
            icon: FiUser,
            label: 'Personal Information',
            route: ROUTES.PERSONAL_INFORMATION_SETTINGS,
            subMenus: [
                // {
                //     icon: FaUniversity,
                //     label: 'Institutions',
                //     route: ROUTES.AFFILIATES_SETTINGS,
                // },
                {
                    icon: MdDeliveryDining,
                    label: 'Shipping',
                    route: ROUTES.SHIPPING_SETTINGS,
                },
                {
                    icon: FaLock,
                    label: 'Reset Password',
                    route: ROUTES.PASSWORD_SETTINGS,
                },
            ],
        },
        {
            icon: BiHome,
            label: 'Home',
            route: ROUTES.MANUSCRIPTS,
        },
    ],
    ManagerialEditor: [
        {
            icon: FiUser,
            label: 'Personal Information',
            route: ROUTES.PERSONAL_INFORMATION_SETTINGS,
            subMenus: [
                // {
                //     icon: FaUniversity,
                //     label: 'Institutions',
                //     route: ROUTES.AFFILIATES_SETTINGS,
                // },
                {
                    icon: MdDeliveryDining,
                    label: 'Shipping',
                    route: ROUTES.SHIPPING_SETTINGS,
                },
                {
                    icon: FaLock,
                    label: 'Reset Password',
                    route: ROUTES.PASSWORD_SETTINGS,
                },
            ],
        },
        {
            icon: BiHome,
            label: 'Home',
            route: ROUTES.MANUSCRIPTS,
        },
    ],
    EditorialSecretary: [
        {
            icon: FiUser,
            label: 'Personal Information',
            route: ROUTES.PERSONAL_INFORMATION_SETTINGS,
            subMenus: [
                // {
                //     icon: FaUniversity,
                //     label: 'Institutions',
                //     route: ROUTES.AFFILIATES_SETTINGS,
                // },
                {
                    icon: MdDeliveryDining,
                    label: 'Shipping',
                    route: ROUTES.SHIPPING_SETTINGS,
                },
                {
                    icon: FaLock,
                    label: 'Reset Password',
                    route: ROUTES.PASSWORD_SETTINGS,
                },
            ],
        },
        {
            icon: BiHome,
            label: 'Home',
            route: ROUTES.DASHBOARD,
        },
    ],
    Blogger: [],
    Support: [],
};
