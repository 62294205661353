import { ComponentStyleConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import { LightenDarkenColor } from '../../lib/utils';
import { colors } from '../colors';

export const ButtonStyles: ComponentStyleConfig = {
    baseStyle: {},
    variants: {
        primary: {
            bg: 'primary',
            color: 'white',
            minHeight: 10,
            _hover: {
                bg: mode(
                    LightenDarkenColor(colors.primary, 20),
                    LightenDarkenColor(colors.primary, -20)
                ) as unknown as string,
                _disabled: {
                    bg: 'primary',
                },
            },
        },
        secondary: {
            bg: 'secondary',
            color: 'white',
            minHeight: 10,
            _hover: {
                bg: mode(
                    LightenDarkenColor(colors.secondary, -20),
                    LightenDarkenColor(colors.secondary, -20)
                ) as unknown as string,
                _disabled: {
                    bg: 'secondary',
                },
            },
        },
        ghost: {
            bg: 'none',
            color: mode(
                LightenDarkenColor(colors.black, 20),
                LightenDarkenColor(colors.white, 20)
            ) as unknown as string,
            minHeight: 10,
            _hover: {
                bg: 'hoverColor',
            },
        },
        alternateDark: {
            bg: 'none',
            color: 'black',
            minHeight: 10,
            _hover: {
                bg: mode(
                    colors.secondary,
                    colors.secondary,
                ) as unknown as string,
                _dark: { color: 'black' },
            },
            _dark: { color: 'white' },
        },
    },
};
