import {
    Box,
    Text,
    Flex,
    Button,
    Heading,
    InputGroup,
    // InputRightElement,
    Input,
    IconButton,
    HStack,
    FormControl,
    useEventListener,
    TagLabel,
    Tag,
    Stack,
    // Stack,
} from '@chakra-ui/react';
import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { colors } from '../../styles/colors';
import { CheckIcon } from '@chakra-ui/icons';
import { Logo } from '../Logo/Logo';
import { getYear } from 'date-fns';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../App.constant';
import {
    RiFacebookLine,
    RiInstagramLine,
    RiLinkedinLine,
    RiTwitterLine,
} from 'react-icons/ri';
import { useCreateNewsLetterRecipient } from '../../services/newsLetterRecipients/newsLetterRecipients.mutations';
import { IoArrowUp } from 'react-icons/io5';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { useAuth } from '../../services/authentication/auth.queries';
import AuthModal from '../modals/AuthModal';

const links = [
    {
        id: 1,
        name: 'FAQ',
        url: ROUTES.FAQS,
    },
    {
        id: 2,
        name: 'News & Blogs',
        url: ROUTES.NEWS_AND_BLOGS,
    },
    {
        id: 3,
        name: 'About Us',
        url: ROUTES.ABOUT_US,
    },
    {
        id: 4,
        name: 'Contact Us',
        url: ROUTES.CONTACT_US,
    },
    {
        id: 5,
        name: 'Privacy Policy',
        url: ROUTES.PRIVACY_POLICY,
    },
    {
        id: 6,
        name: 'Terms & Condition',
        url: ROUTES.TERMS_AND_CONDITIONS,
    },
];

export default function PublicFooter() {
    const {
        mutateAsync: createNewsLetterRecipient,
        isSuccess,
        isLoading,
    } = useCreateNewsLetterRecipient();
    const [email, setEmail] = useState('');
    const [state, setState] = useState<'initial' | 'submitting' | 'success'>(
        'initial'
    );
    const { data } = useAuth();
    const navigate = useNavigate();
    const [showAuthModal, setShowModal] = useState<boolean>(false);

    const [token, setToken] = useState<boolean>(false);
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const [showTopBtn, setShowTopBtn] = useState(false);
    useEventListener('scroll', () => {
        if (window.scrollY > 400) {
            setShowTopBtn(true);
        } else {
            setShowTopBtn(false);
        }
    });

    const isLocalhost =
        window.location.hostname === 'localhost' ||
        window.location.hostname === '127.0.0.1';

    const onVerify = (getToken: any) => {
        setToken(getToken);
    };

    const onCreateNewsLetterRecipient = async () => {
        try {
            setState('submitting');

            await createNewsLetterRecipient({ email });
            setState('success');
            setRefreshReCaptcha(!refreshReCaptcha);
        } catch (e) {
            setRefreshReCaptcha(!refreshReCaptcha);

            console.log(e);
            setState('initial');
        }
    };

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const calcScrollValue = () => {
            const scrollProgress = document.getElementById('progress');
            const pos = document.documentElement.scrollTop;
            const calcHeight =
                document.documentElement.scrollHeight -
                document.documentElement.clientHeight;
            const scrollValue = Math.round((pos * 100) / calcHeight);
            if (scrollProgress) {
                if (pos > 100) {
                    scrollProgress.style.display = 'grid';
                } else {
                    scrollProgress.style.display = 'none';
                }
                scrollProgress.addEventListener('click', () => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                });
                scrollProgress.style.background = `conic-gradient(#899878 ${scrollValue}%, #d7d7d7 ${scrollValue}%)`;
            }
        };
        window.onscroll = calcScrollValue;
        window.onload = calcScrollValue;
    }, []);

    return (
        <>
            <AuthModal
                setShowAuthModal={setShowModal}
                showAuthModal={showAuthModal}
                isCreatingManuscript={true}
            />

            <Box px={4} py={16} bg={colors.primary} zIndex={5}>
                <Box maxW={{ base: '100%', xl: '70%' }} mx="auto">
                    <Stack
                        bg="rgba(255, 255, 255, 0.2)"
                        mx="auto"
                        boxShadow={'xl'}
                        rounded={'lg'}
                        display={{ base: 'none', md: 'flex' }}
                    >
                        <Flex
                            mx="auto"
                            w={'100%'}
                            justifyContent="space-between"
                            alignItems="start"
                            px="20px"
                            py={3}
                            as={'form'}
                            onSubmit={(e: FormEvent) => {
                                e.preventDefault();
                                onCreateNewsLetterRecipient();
                            }}
                        >
                            <Heading
                                as={'p'}
                                fontSize={{ base: 'xl', sm: '2xl' }}
                                textAlign={'center'}
                                color={colors.white}
                            >
                                Newsletter Sign Up
                            </Heading>
                            <InputGroup
                                bg="white"
                                maxW="60%"
                                py="4px"
                                borderRadius="5px"
                                px={{ base: '0px', lg: '10px' }}
                            >
                                <Input
                                    pr="9.0rem"
                                    _placeholder={{
                                        opacity: 1,
                                        color: '#222725',
                                    }}
                                    bg="white"
                                    width="100%"
                                    required
                                    id={'email'}
                                    type={'email'}
                                    placeholder={'Enter your email here...'}
                                    aria-label={'Your Email'}
                                    value={email}
                                    disabled={state !== 'initial'}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => setEmail(e.target.value)}
                                />
                            </InputGroup>
                        </Flex>

                        <Stack
                            width={'60%'}
                            alignSelf={'flex-end'}
                            px={4}
                            display={'flex'}
                            gap={4}
                            flexDirection={'row'}
                            justifyContent={'end'}
                        >
                            {!isLocalhost && (
                                <GoogleReCaptchaProvider
                                    reCaptchaKey={
                                        process.env
                                            .REACT_APP_RECAPTCHA_SITE_KEY as string
                                    }
                                >
                                    <GoogleReCaptcha
                                        onVerify={onVerify}
                                        refreshReCaptcha={refreshReCaptcha}
                                    />
                                </GoogleReCaptchaProvider>
                            )}
                            <Button
                                variant="solid"
                                width="max-content"
                                // mt="10px"
                                mb={2}
                                bg={colors.lightPrimary}
                                isLoading={isLoading}
                                type={state === 'success' ? 'button' : 'submit'}
                                isDisabled={!isLocalhost && !token}
                                onClick={() => onCreateNewsLetterRecipient()}
                            >
                                {isSuccess ? <CheckIcon /> : ' Subscribe'}
                            </Button>
                        </Stack>
                    </Stack>

                    <Box
                        textAlign="center"
                        display={{ base: 'block', md: 'none' }}
                    >
                        <Heading as="h1" color={colors.white}>
                            Newsletter Sign Up
                        </Heading>
                        <Box
                            p={3}
                            bg="rgba(255, 255, 255, 0.2)"
                            mt="25px"
                            as={'form'}
                            borderRadius="10px"
                            onSubmit={(e: FormEvent) => {
                                e.preventDefault();
                            }}
                        >
                            <FormControl>
                                <Input
                                    size="lg"
                                    bg={colors.white}
                                    required
                                    id={'email'}
                                    type={'email'}
                                    placeholder={'Enter your email here...'}
                                    aria-label={'Your Email'}
                                    value={email}
                                    disabled={state !== 'initial'}
                                    onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                    ) => setEmail(e.target.value)}
                                />
                            </FormControl>
                            {!isLocalhost && (
                                <Box float="left" width={'100%'}>
                                    <GoogleReCaptchaProvider
                                        reCaptchaKey={
                                            process.env
                                                .REACT_APP_RECAPTCHA_SITE_KEY as string
                                        }
                                    >
                                        <GoogleReCaptcha
                                            onVerify={onVerify}
                                            refreshReCaptcha={refreshReCaptcha}
                                        />
                                    </GoogleReCaptchaProvider>
                                </Box>
                            )}
                            <FormControl>
                                <Button
                                    variant="solid"
                                    width="100%"
                                    mt="20px"
                                    bg={colors.lightPrimary}
                                    isLoading={isLoading}
                                    type={
                                        state === 'success'
                                            ? 'button'
                                            : 'submit'
                                    }
                                    isDisabled={!isLocalhost && !token}
                                    onClick={() =>
                                        onCreateNewsLetterRecipient()
                                    }
                                >
                                    {isSuccess ? <CheckIcon /> : ' Subscribe'}
                                </Button>
                            </FormControl>
                        </Box>
                    </Box>
                    <Flex
                        color={colors.white}
                        justifyContent="space-between"
                        mt="45px"
                        flexDirection={{ base: 'column', md: 'row' }}
                    >
                        <Box>
                            <Flex
                                width={{
                                    base: '100%',
                                    md: '15rem',
                                    lg: '20rem',
                                }}
                                gap={6}
                                alignItems={'flex-start'}
                            >
                                <Logo />

                                <Stack flexDirection={'column'} gap={2}>
                                    <Text>
                                        No. 10, Araromi Street, off High School,
                                        Akure, Ondo State, Nigeria
                                    </Text>
                                    <Text>
                                        Al Adiyat Residence 2, Al Barsha 1,
                                        Dubai, United Arab Emirates
                                    </Text>
                                    <Box
                                        mt={{ base: '5px', md: '35px' }}
                                        lineHeight={2}
                                    >
                                        <Text>+234 902 453 4541</Text>
                                        <Text>support@primaljournals.org</Text>
                                    </Box>
                                    <HStack
                                        mt={4}
                                        ml="70px"
                                        alignItems="flex-start"
                                    >
                                        <IconButton
                                            variant="outline"
                                            colorScheme="primary"
                                            aria-label="Primal Instagram Account"
                                            icon={<RiInstagramLine />}
                                            as={'a'}
                                            href={`https://www.instagram.com/primaljournals/`}
                                            target="_blank"
                                        />
                                        <IconButton
                                            variant="outline"
                                            colorScheme="primary"
                                            aria-label="Primal Twitter Account"
                                            icon={<RiTwitterLine />}
                                            as={'a'}
                                            href={`https://twitter.com/primaljournals`}
                                            target="_blank"
                                        />
                                        <IconButton
                                            variant="outline"
                                            colorScheme="primary"
                                            aria-label="Primal Twitter Facebook"
                                            icon={<RiFacebookLine />}
                                            as={'a'}
                                            href={`https://www.facebook.com/Primaljournals`}
                                            target="_blank"
                                        />
                                        <IconButton
                                            variant="outline"
                                            colorScheme="primary"
                                            aria-label="Primal Linkedin"
                                            icon={<RiLinkedinLine />}
                                            as={'a'}
                                            href={`https://www.linkedin.com/company/primal-journals`}
                                            target="_blank"
                                        />
                                    </HStack>
                                </Stack>
                            </Flex>
                        </Box>
                        <Box
                            color={colors.white}
                            mt={{ base: '50px', md: '0px' }}
                        >
                            <Text fontSize="16px" fontWeight="bold">
                                Quick Links
                            </Text>
                            <Box>
                                {links.map((link) => {
                                    return (
                                        <Text
                                            key={link.id}
                                            as={Link}
                                            to={link.url}
                                            display="block"
                                            lineHeight={2}
                                            opacity={0.75}
                                            onClick={() => goToTop()}
                                        >
                                            {link.name}
                                        </Text>
                                    );
                                })}
                            </Box>
                        </Box>
                        <Box lineHeight={2} mt={{ base: '25px', md: '0px' }}>
                            <Text fontSize="16px" fontWeight="bold">
                                For Publishing
                            </Text>
                            <Box opacity={0.75}>
                                <Text
                                    as={Link}
                                    to={ROUTES.BECOME_REVIEWER}
                                    onClick={() => goToTop()}
                                >
                                    For Reviewers
                                </Text>
                                <Text>
                                    Become an Editor
                                    <Tag color={colors.secondary}>
                                        <TagLabel
                                            fontSize="xs"
                                            fontWeight="extrabold"
                                        >
                                            Coming soon
                                        </TagLabel>
                                    </Tag>
                                </Text>
                                <Text>
                                    Become a partner
                                    <Tag color={colors.secondary}>
                                        <TagLabel
                                            fontSize="xs"
                                            fontWeight="extrabold"
                                        >
                                            Coming soon
                                        </TagLabel>
                                    </Tag>
                                </Text>
                                <Text
                                    fontWeight={700}
                                    cursor={'pointer'}
                                    _hover={{
                                        fontWeight: 700,
                                    }}
                                    onClick={() => {
                                        if (!data) {
                                            setShowModal(true);
                                        } else {
                                            navigate(ROUTES.NEW_MANUSCRIPT);
                                        }
                                    }}
                                >
                                    Submit a manuscript
                                </Text>
                            </Box>
                        </Box>
                    </Flex>
                    <Box
                        mt="50px"
                        borderTopColor={colors.borderColor}
                        borderTopWidth="1px"
                        textAlign="center"
                        color={colors.white}
                    >
                        <Text opacity={0.75} pt="30px">
                            © {getYear(new Date())} Primal Journals LTD. All
                            rights reserved
                        </Text>
                    </Box>
                </Box>
                {showTopBtn && (
                    <div id="progress">
                        <span id="progress-value">
                            <IoArrowUp />
                        </span>
                    </div>
                )}
            </Box>
        </>
    );
}
