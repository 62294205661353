import { Box } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface IProps {
    children: ReactNode;
}
export const AppMain = ({ children }: IProps) => {
    return (
        <Box as="main" mt={'66px'}>
            {children}
        </Box>
    );
};
