import { ComponentStyleConfig } from '@chakra-ui/react';

export const SelectStyles: ComponentStyleConfig = {
  baseStyle: {
    field: {
      bg: 'secondary',
      color: 'black',
    },
  },
  defaultProps: {
    variant: null,
  },
};
