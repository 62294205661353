import { localStorageKeys, useLocalStorage } from './useLocalStorage';
import axios, { AxiosRequestConfig } from 'axios';
import { apiHttp } from '../lib/appConfig';
import { useDebouncedCallback } from 'use-debounce';
import { IAuth, ROLES_ENUM } from '@primaljournal/commons';

export type ServerResponse<T> = {
    message: string;
    data: T;
    status: boolean;
};

const useAxiosConfig = () => {
    const { resetLocalStorage } = useLocalStorage();
    const debouncedLogout = useDebouncedCallback(async () => {
        resetLocalStorage();
        window.location.href = '/';
        window.location.reload();
    }, 100);

    apiHttp.interceptors.response.use(
        (axios) => {
            return axios;
        },
        function (error) {
            if (axios.isAxiosError(error)) {
                const errorObj = error.response?.data as ServerResponse<any>;
                console.log(errorObj);

                //🔐 Logout if token is invalid
                if (errorObj?.message === "NOT_AUTHORIZED") {
                    debouncedLogout();
                    return;
                }
            }

            console.log(
                '[Intercepted Response]',
                JSON.stringify(error.message)
            );

            return Promise.reject(error);
        }
    );
    apiHttp.interceptors.request.use(
        (config: AxiosRequestConfig) => {
            const auth = JSON.parse(
                localStorage.getItem(localStorageKeys.auth) as string
            ) as IAuth;

            const activeRole = localStorage.getItem(
                localStorageKeys.preferredProfile
            );

            if (auth && config.headers && auth.accessToken) {
                config.headers['x-access-token'] = auth.accessToken;
            }

            // set active role
            if (activeRole) {
                if (!config.params) {
                    config['params'] = {};
                }

                if (ROLES_ENUM.includes(JSON.parse(activeRole).trim() as any)) {
                    config.params['role'] = activeRole.replaceAll('"', '');
                }
            }

            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );
};

export default useAxiosConfig;
