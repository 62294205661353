import { ComponentStyleConfig } from '@chakra-ui/react';

export const CheckboxStyles: ComponentStyleConfig = {
    baseStyle: {
        control: {
            borderColor: 'primary',
            color: 'black',
            _dark: {
                color: 'black',
                _hover: 'none',
            },
            _checked: {
                bg: 'primary',
                borderColor: 'primary',
                _hover: 'none',
            },
            _indeterminate: {
                bg: 'primary',
                borderColor: 'primary',
                _hover: 'none',
            },
            _active: {
                bg: 'primary',
                borderColor: 'primary',
                _hover: 'none',
            },
        },
    },
    defaultProps: {
        variant: null, // null here
    },
};
