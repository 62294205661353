import { colors } from '../colors';
import { ComponentStyleConfig } from '@chakra-ui/react';

export const RadioStyles: ComponentStyleConfig = {
    baseStyle: {
        control: {
            borderColor: colors.primary,
            _checked: {
                borderColor: colors.primary,
                background: colors.primary,
                _hover: {
                    background: colors.primary,
                    borderColor: colors.primary,
                },
            },
        },
    },
    defaultProps: {
        variant: null,
    },
};
