import { Box, Stack, Button, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ErrorComponentProps } from '../../lib/HOCs/withErrorBoundary';

const NotFoundView = ({ resetErrorBoundary, props }: ErrorComponentProps) => {
    const navigate = useNavigate();
    return (
        <Box
            as={Stack}
            gap={8}
            direction="column"
            justifyContent="center"
            {...props}
        >
            <Text>Oops, Content could not be found 😣</Text>
            <Stack gap={4}>
                <Button onClick={resetErrorBoundary}>Try again</Button>
                <Button onClick={() => navigate('/')}>Go back</Button>
            </Stack>
        </Box>
    );
};

export default NotFoundView;
