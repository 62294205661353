import {
    Button,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';
import React, { SetStateAction } from 'react';
import { colors } from '../../styles/colors';
import { InfoIcon } from '@chakra-ui/icons';
// import { InfoIcon } from '@chakra-ui/icons';

interface modalProps {
    isOpen: boolean;
    onClose: any;
    setIsOpen: React.Dispatch<SetStateAction<boolean>>;
    Function: VoidFunction;
    loading?: boolean;
    message: string;
    buttonText: string;
    cancelButtonText?: string;
    cancelFunction?: VoidFunction;
    minWidth?: string[];
    additionalInfomation?: string;
}

const ActionModal = ({
    isOpen,
    onClose,
    setIsOpen,
    Function,
    loading,
    message,
    buttonText,
    cancelButtonText,
    cancelFunction,
    minWidth,
    additionalInfomation,
}: modalProps) => {
    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent minWidth={minWidth ? minWidth : '20rem'}>
                <ModalBody display={'flex'} flexDir={'column'} gap={4}>
                    <Text textAlign={'center'}>{message}</Text>

                    {additionalInfomation && (
                        <Flex gap={4} width={'100%'}>
                            <Text textAlign={'center'} fontWeight={'bold'}>
                                <span>
                                    <InfoIcon />
                                </span>{' '}
                                {additionalInfomation}
                            </Text>
                        </Flex>
                    )}
                </ModalBody>

                <ModalFooter
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    gap={4}
                    flexDirection={{ base: 'column-reverse', md: 'row' }}
                >
                    <Button
                        background={colors.secondary}
                        color={colors.black}
                        // onClick={() => setIsOpen(false)}
                        onClick={() => {
                            if (cancelFunction) {
                                cancelFunction();
                            } else {
                                setIsOpen(false);
                            }
                        }}
                    >
                        {cancelButtonText || `No, Cancel`}
                    </Button>
                    <Button
                        variant="ghost"
                        backgroundColor={colors.primary}
                        color={colors.white}
                        onClick={Function}
                        isLoading={loading}
                    >
                        {buttonText}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ActionModal;
