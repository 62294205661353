import { TextareaStyles as Textarea } from './components/TextArea';
import { SelectStyles as Select } from './components/Select';
import { ColorMode, extendTheme } from '@chakra-ui/react';
import { mode, Styles, transparentize } from '@chakra-ui/theme-tools';
import { InputStyles as Input } from './components/InputStyles';
import { ButtonStyles as Button } from './components/ButtonStyles';
import { InputRightElementStyles as InputRightElement } from './components/InputRightElement';
import { RadioStyles as Radio } from './components/RadioStyles';
import { colors, dynamicLightDarkColorConfig } from './colors';
import { CheckboxStyles as Checkbox } from './components/CheckboxStyles';

const styles: Styles = {
    global: (props) => ({
        body: {
            color: mode(colors.black, colors.white)(props),
            bg: mode('white', colors.black)(props),
        },
    }),
};

export const theme = extendTheme({
    config: {
        initialColorMode: 'light',
        useSystemColorMode: false,
    },
    styles,
    colors,
    components: {
        Input,
        Button,
        InputRightElement,
        Textarea,
        Select,
        Radio,
        Checkbox,
        HoverBox: {
            baseStyle: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                transition: 'all 0.2s',
                '&:hover': {
                    transform: 'translateX(8px)',
                    '& > div:last-child': {
                        opacity: 1,
                    },
                },
                '& > div:last-child': {
                    transition: 'opacity 0.2s ease-in-out',
                },
            },
        },
    },
});

export const useDynamicLightOrDarkColor = (mode: ColorMode) => {
    return dynamicLightDarkColorConfig[mode];
};

export const useColor = (color: string, opacity = 1) => {
    return transparentize(color, opacity) as unknown as string;
};

export const useBaseColor = (mode: ColorMode, opacity = 1) => {
    const modeConfig = {
        light: {
            color: 'gray.800',
            bg: transparentize('white', opacity)(theme),
        },
        dark: {
            color: 'white',
            bg: transparentize(colors.black, opacity)(theme),
        },
    };

    return modeConfig[mode];
};

export const useHoverColor = (mode: ColorMode) => {
    const modeConfig = {
        light: {
            color: colors.primary,
            bg: colors.primary,
        },
        dark: {
            color: colors.primary,
            bg: colors.primary,
        },
    };

    return modeConfig[mode];
};
